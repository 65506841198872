import React from "react";
export const TRACKING_STATUSES_DISPLAY = ["Verified", "Shipped", "Delivered"];
export const TRACKING_STATUSES = ["created", "shipped", "delivered"];
const TrackingProgressBar = ({ currentStatus, variant = "secondary" }) => {
  const currentIndex = TRACKING_STATUSES.findIndex(
    (status) => status === currentStatus
  );
  const completionPercentage = currentIndex === 0 ? "25%" : "65%";
  const textClassname =
    variant === "secondary" ? "text-secondary" : "text-primary";
  const bgClassname =
    variant === "secondary"
      ? "bg-gradient-to-b from-secondary-200 to-secondary"
      : "bg-primary";
  return (
    <>
      <div className="flex justify-between mb-1.5 text-sm font-medium">
        {TRACKING_STATUSES_DISPLAY.map((status, index) => (
          <span
            key={status}
            className={index <= currentIndex ? textClassname : "text-gray-300"}
          >
            {status}
          </span>
        ))}
      </div>
      <div className="px-6 mb-4">
        <div className="w-full h-1 bg-gray-200 rounded">
          <div
            className={`h-1 rounded ${bgClassname}`}
            style={{ width: completionPercentage }}
          />
          <div className="flex justify-between -m-2">
            {TRACKING_STATUSES.map((status, index) => (
              <span
                key={status}
                className={`block w-3 h-3 rounded-full ${
                  index <= currentIndex
                    ? bgClassname
                    : `border-${variant} bg-white border`
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackingProgressBar;
