import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useServerAPI from "../../../customHooks/useServerAPI";
import Button from "../../bits/Button";
import Modal from "../../bits/Modal";
import InputGroup from "../InputGroup";

const ChangePasswordModal = ({ show, setShow, onComplete }) => {
  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    oldPassword: [],
    newPassword: [],
    confirmPassword: [],
  });
  const [touched, setTouched] = useState({});

  const [changePasswordResponse, , changePassword] =
    useServerAPI("changePassword");

  const isDisabled = useMemo(
    () => Object.values(errors).some((each) => each.length > 0),
    [errors]
  );

  const handleBlur = (ev) => {
    const { name, value, placeholder } = ev.target;
    setTouched((prev) => ({ ...prev, [name]: true }));
    if (value.length < 9) {
      setErrors((prev) => ({
        ...prev,
        [name]: [placeholder + " must be at least 9 characters long."],
      }));
    } else if (!/[A-Z]+/.test(value)) {
      setErrors((prev) => ({
        ...prev,
        [name]: [placeholder + " must contain at least one uppercase letter."],
      }));
    } else if (!/[0-9]+/.test(value)) {
      setErrors((prev) => ({
        ...prev,
        [name]: [placeholder + " must contain at least one digit."],
      }));
    } else if (!/[a-z]+/.test(value)) {
      setErrors((prev) => ({
        ...prev,
        [name]: [placeholder + " must contain at least one lowercase letter."],
      }));
    }
    if (
      errors.confirmPassword?.length === 0 &&
      values.confirmPassword !== values.newPassword
    ) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: ["Confrim Password not matched"],
      }));
    } else if (
      errors.confirmPassword[0] === "Confrim Password not matched" &&
      values.confirmPassword === values.newPassword
    ) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: [],
      }));
    }
    if (
      errors.newPassword?.length === 0 &&
      values.oldPassword === values.newPassword
    ) {
      setErrors((prev) => ({
        ...prev,
        newPassword: ["New Password should be different from Old password"],
      }));
    } else if (
      errors.newPassword[0] ===
        "New Password should be different from Old password" &&
      values.oldPassword !== values.newPassword
    ) {
      setErrors((prev) => ({
        ...prev,
        newPassword: [],
      }));
    }
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (!isDisabled) {
      changePassword({
        old_password: values.oldPassword,
        new_password: values.newPassword,
        confirm_password: values.confirmPassword,
      });
    }
  };
  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: [] }));
  };

  useEffect(() => {
    if (
      changePasswordResponse &&
      changePasswordResponse?.message === "Password changed successfully"
    ) {
      setShow(false);
      onComplete();
    } else if (
      changePasswordResponse &&
      changePasswordResponse?.result?.status !== 200
    ) {
      if (!changePasswordResponse?.result?.data?.error) {
        setErrors(changePasswordResponse?.result?.data);
      } else {
        toast.error(
          changePasswordResponse?.result?.data?.error ||
            changePasswordResponse?.message
        );
      }
    }
  }, [changePasswordResponse, onComplete, setShow]);
  return (
    <Modal
      show={show}
      setShow={setShow}
      onCloseModal={() => {
        setErrors({
          oldPassword: [],
          newPassword: [],
          confirmPassword: [],
        });
        setValues({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      }}
      showCrossIcon
    >
      <form className="w-full text-center" onSubmit={handleSubmit}>
        <h1 className="text-2xl font-bold">Change Password</h1>
        <InputGroup
          name="oldPassword"
          label="Old Password"
          required
          type="password"
          value={values.oldPassword}
          placeholder="Old Password"
          handleChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.oldPassword &&
          errors.oldPassword.map((err) => (
            <p key={err} className="text-red-500">
              {err}
            </p>
          ))}
        <InputGroup
          name="newPassword"
          label="New Password"
          required
          value={values.newPassword}
          placeholder="New Password"
          type="password"
          handleChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.newPassword &&
          errors.newPassword.map((err) => (
            <p key={err} className="text-red-500">
              {err}
            </p>
          ))}
        <InputGroup
          name="confirmPassword"
          label="Confirm Password"
          required
          type="password"
          value={values.confirmPassword}
          placeholder="Confirm Password"
          handleChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.confirmPassword &&
          errors.confirmPassword.map((err) => (
            <p key={err} className="text-red-500">
              {err}
            </p>
          ))}
        <Button className="w-40 my-5" type="submit" disabled={isDisabled}>
          Submit
        </Button>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
