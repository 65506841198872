import React, { useMemo, useState } from "react";
import { getValueByPath } from "../../../helpers/getValueByPath";
import Button from "../../bits/Button";
import CheckboxInputGroup from "../CheckboxInputGroup";
import FileInputGroup from "../FileInputGroup";
import InputGroup from "../InputGroup";
import RadioInputGroup from "../RadioInputGroup";

const Form = ({
  onSubmit,
  fields = [],
  initialValues = {},
  buttonText = "Continue",
}) => {
  const [formValues, setFormValues] = useState(initialValues);

  const isCurrentStepCompleted = useMemo(
    () =>
      fields.every(({ name, hideWhen, required }) => {
        if (!required) return true;

        return (
          getValueByPath(formValues, hideWhen || "", false) || formValues[name]
        );
      }),
    [fields, formValues]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (onSubmit) onSubmit(formValues);
  };

  const handleChange = (event) => {
    const { value, name, checked, type } = event.target;
    if (type === "checkbox") {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: { ...(prevValues[name] || {}), [value]: checked },
      }));
    } else {
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
  };

  const handleFileChange = async (name, file) => {
    if (file) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: file,
      }));
    } else
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: undefined,
      }));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col justify-between w-full px-2 grow"
    >
      <div className="mb-8">
        {fields.map((field) => {
          const { inputType = "input", name, hideWhen, ...inputProps } = field;
          if (hideWhen && getValueByPath(formValues, hideWhen, false)) return;
          switch (inputType) {
            case "input":
              return (
                <InputGroup
                  key={name}
                  name={name}
                  value={formValues[name]}
                  handleChange={handleChange}
                  {...inputProps}
                />
              );
            case "file":
              return (
                <FileInputGroup
                  key={name}
                  name={name}
                  value={formValues[name]}
                  handleFileChange={(file) => handleFileChange(name, file)}
                  {...inputProps}
                />
              );
            case "radio":
              return (
                <RadioInputGroup
                  key={name}
                  name={name}
                  value={formValues[name]}
                  handleChange={handleChange}
                  {...inputProps}
                />
              );
            case "checkbox":
              return (
                <CheckboxInputGroup
                  key={name}
                  name={name}
                  values={formValues[name]}
                  handleChange={handleChange}
                  {...inputProps}
                />
              );
          }
        })}
      </div>
      <Button type="submit" disabled={!isCurrentStepCompleted}>
        {buttonText}
      </Button>
    </form>
  );
};

export default Form;
