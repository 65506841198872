import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { downloadFileFromGCS, isUndefined } from "../../../helpers/utils";
import LoadingSpinner from "../../bits/LoadingSpinner";

const FileInputGroup = ({
  label,
  handleFileChange,
  value,
  placeholder,
  enableViewImage = false,
  ...props
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const ref = useRef();
  const onFileChange = (event) => {
    setIsUploading(true);
    handleFileChange(event.target.files[0]);
  };

  useEffect(() => {
    if (value) {
      setIsUploading(false);
    }
    return () => {};
  }, [value]);

  const downloadFile = (file) => {
    if (isUndefined(file.type) && !isUndefined(file.name)) {
      downloadFileFromGCS(file.name);
    } else {
      const elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(file);
      elem.download = file.name;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  };

  return (
    <div className="my-3">
      <h3 className="font-semibold">{label}</h3>
      {value?.name ? (
        <div className="flex flex-col items-center justify-center w-full my-2 font-semibold border-2 border-dashed h-52 rounded-xl border-primary">
          {enableViewImage ? (
            <div className="relative w-full h-full group ">
              <Image
                src={window.URL.createObjectURL(value)}
                layout="fill"
                className="rounded-xl"
                alt={value?.name}
              />
              <div className="absolute z-10 items-center justify-center hidden w-full h-full cursor-pointer group-hover:flex text-primary backdrop-blur-sm rounded-xl">
                <button className="px-3 py-2 bg-gray-300 rounded-xl bg-opacity-60">
                  View Image
                </button>
              </div>
            </div>
          ) : (
            <>
              <span className="mb-2.5">
                <Image
                  src="/assets/icons/xlsxFile.svg"
                  alt="upload"
                  height={32}
                  width={40}
                />
              </span>
              {value.name}
              <div className="flex justify-between w-24 my-4">
                <span
                  className="cursor-pointer"
                  onClick={() => handleFileChange()}
                >
                  <Image
                    src="/assets/icons/delete.svg"
                    alt="delete"
                    height={24}
                    width={24}
                  />
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() => downloadFile(value)}
                >
                  <Image
                    src="/assets/icons/download.svg"
                    alt="download"
                    height={24}
                    width={24}
                  />
                </span>
              </div>
            </>
          )}
        </div>
      ) : (
        <label
          htmlFor={props.name}
          className="flex items-center justify-center w-full my-2 border-2 border-dashed cursor-pointer h-52 rounded-xl border-primary"
        >
          {isUploading ? (
            <div>
              <LoadingSpinner />
              Uploading..
            </div>
          ) : (
            <>
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <span className="mb-2.5">
                  <Image
                    src="/assets/icons/upload.svg"
                    alt="delete"
                    height={30}
                    width={30}
                  />
                </span>
                <p className="mb-2 font-semibold text">{placeholder}</p>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold text-primary">Tap here</span>{" "}
                  to select from device
                </p>
              </div>
              <input
                id={props.name}
                type="file"
                name={props.name}
                ref={ref}
                className="hidden"
                onChange={onFileChange}
                {...props}
              />{" "}
            </>
          )}
        </label>
      )}
    </div>
  );
};

export default FileInputGroup;
