import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import {
  getCookieItem,
  getInitials,
  getIsFieldExecutive,
} from "../../../helpers/utils";
import TextLink from "../../bits/TextLink";

const NAVIAGTION_TABS = [
  {
    label: "Dashboard",
    imageUrl: "/assets/icons/homeLight.svg",
    imageAlt: "home",
    path: "/",
  },
  {
    label: "My Orders",
    imageUrl: "/assets/icons/ordersLight.svg",
    imageAlt: "orders",
    path: "/orders",
  },
  {
    label: "Payments",
    imageUrl: "/assets/icons/paymentLight.svg",
    imageAlt: "payments",
    path: "/payments",
  },
  {
    label: "Profile",
    imageUrl: "/assets/icons/profileLight.svg",
    imageAlt: "profile",
    path: "/profile",
  },
  {
    label: "Notifications",
    imageUrl: "/assets/icons/notification.svg",
    imageAlt: "notifications",
    path: "/notifications",
  },
  {
    label: "Support",
    imageUrl: "/assets/icons/customer-support.svg",
    imageAlt: "support",
  },
];

const SideMenuBar = ({
  onClose,
  user,
  logout,
  isApproved,
  isASMUser,
  changePassword,
}) => {
  const router = useRouter();
  const isFieldExecutive = getIsFieldExecutive();
  const passwordLoginEnabled = getCookieItem("passwordLoginEnabled");
  return (
    <div
      className="absolute z-[999] w-full max-w-3xl h-screen text-xl backdrop-blur-[2px] flex items-center"
      onClick={onClose}
    >
      <div
        className="flex flex-col justify-between w-2/3 h-full px-5 py-8 bg-white rounded shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex">
          <span className="p-2 mr-3.5 text-xl font-semibold text-center border-2 border-black rounded-full w-12 h-12">
            {getInitials(user.business?.name || user.name)}
          </span>
          <p className="text-lg font-semibold leading-tight">
            <span className="text-sm font-light text-gray-500">Welcome,</span>
            <br />
            {user.business?.name || user.name}
          </p>
        </div>
        {!isASMUser && (
          <div className="flex flex-col justify-center space-y-6">
            {NAVIAGTION_TABS.filter(
              (each) => !isFieldExecutive || !each.path?.includes("/payments")
            ).map(
              (tab) =>
                (!isApproved &&
                  tab.path !== "/" &&
                  tab.path !== "/profile") || (
                  <a
                    href={tab.label === "Support" ? "tel:+919311962746" : "#"}
                    key={tab.label}
                  >
                    <div
                      className="flex space-x-4"
                      onClick={() => tab.path && router.push(tab.path)}
                    >
                      <Image
                        src={tab.imageUrl}
                        alt={tab.imageAlt}
                        height={30}
                        width={30}
                      />
                      <TextLink className="!font-medium">{tab.label}</TextLink>
                    </div>
                  </a>
                )
            )}
          </div>
        )}
        {passwordLoginEnabled && (
          <div className="flex space-x-4" onClick={changePassword}>
            <Image
              src="/assets/icons/profileLight.svg"
              alt="ChangePassword"
              height={30}
              width={30}
            />
            <TextLink className="!font-medium">Change Password</TextLink>
          </div>
        )}
        <span />
        <div className="space-y-4">
          <div className="flex space-x-4" onClick={logout}>
            <Image
              src="/assets/icons/logout.svg"
              alt="Logout"
              height={30}
              width={30}
            />
            <span className="font-medium cursor-pointer">Logout</span>
          </div>
          <div className="inline-flex space-x-4">
            <a href="/terms" target="_blank" className="text-base">
              Terms of use
            </a>
            <a href="#" className="text-base">
              Privacy Policy
            </a>
          </div>
          <h2 className="text-base">App version v1.0.1</h2>
        </div>
      </div>
    </div>
  );
};

export default SideMenuBar;
