const RadioOption = ({ name, value, label, ...props }) => {
  return (
    <div className="flex items-center mt-2">
      <input
        type="radio"
        id={value}
        value={value}
        name={name}
        className="inline-block w-4 h-4 mr-2 border-2 rounded appearance-none checked:bg-checked checked:border-none peer"
        {...props}
      />
      <label
        htmlFor={value}
        className="text-[#bdbdbd] cursor-pointer peer-checked:text-primary"
      >
        {label}
      </label>
    </div>
  );
};

export default RadioOption;
