import React from "react";
import CheckboxOption from "../../bits/CheckboxOption";

const CheckboxInputGroup = ({
  options = [],
  name = "",
  handleChange,
  label,
  values = {},
  ...inputProps
}) => {
  return (
    <div className="my-3">
      <h3 className="font-semibold">{label}</h3>
      <div className="flex justify-between">
        {options.map((option) => (
          <CheckboxOption
            value={option.value}
            label={option.label}
            key={option.value}
            name={name}
            onChange={handleChange}
            checked={values[option.value]}
            {...inputProps}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckboxInputGroup;
