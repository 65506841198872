import Image from "next/image";

// TODO:- Need to remove once all images were added to DMS api.
const DummyCarAPIImages = [
  "nan",
  "https://fixcraft.s3.amazonaws.com/static/master/741e6094-8e6f-4519-9614-403d74cba31d.jpg",
  "https://fixcraft.s3.amazonaws.com/static/master/",
  "https://fixcraft.s3.amazonaws.com/static/master/NO_IMAGE.png",
  "https://fixcraft.s3.amazonaws.com/static/master/c2102a9a-6ab9-4cbd-9994-16be2e1efb21.png",
  "https://fixcraft.s3.amazonaws.com/static/master/9d8cef40-ac1b-49e9-8276-ec9bb8f0399d.png",
  "https://fixcraft.s3.amazonaws.com/static/master/5696236a-e264-40ea-8ff3-2fd5dc8150b4.png",
];
const CarCard = ({
  children,
  imageUrl,
  brand,
  model,
  year,
  variant,
  fuelType,
  generation = "",
  itemCount,
  onSelect,
  hideBackground = false,
}) => {
  return (
    <div
      onClick={onSelect}
      className={`flex items-center justify-between w-full p-4 bg-gray-100 border rounded-lg cursor-pointer flex-wrap ${
        hideBackground ? "bg-opacity-0 border-none" : ""
      }`}
    >
      <div className="inline-flex space-x-6">
        <span className="block w-20">
          {imageUrl && !DummyCarAPIImages.includes(imageUrl) ? (
            <Image src={imageUrl} height={56} width={77} alt={model} />
          ) : (
            <Image
              src="/assets/icons/dummyCar.svg"
              height={44}
              width={44}
              alt={model}
            />
          )}
        </span>
        <p className="text-lg font-semibold leading-tight">
          <span className="text-sm font-normal text-[#bdbdbd]">{brand}</span>
          <br />
          {model}
        </p>
      </div>
      {itemCount ? (
        <p className="text-sm font-normal text-[#bdbdbd]">
          {year} <br />
          {variant}
        </p>
      ) : (
        <>
          <p className="text-sm font-normal text-[#bdbdbd]">{year}</p>
          <p className="text-sm font-normal text-[#bdbdbd]">
            {variant} <br />
            {generation}
          </p>
        </>
      )}
      <p className="text-sm font-normal text-[#bdbdbd]">{fuelType}</p>
      {itemCount && (
        <p className="text-sm font-normal text-[#bdbdbd]">{itemCount} items</p>
      )}
      {children}
    </div>
  );
};

export default CarCard;
