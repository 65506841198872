import {
  CART_CLOSE,
  CART_OPEN,
  CREATE_ORDER,
  RESET,
  RESET_CART,
  UPDATE_ORDER,
  UPDATE_ORDER_ITEM,
} from "./actions";

export const INITIAL_STATE = {
  isCartOpen: false,
};

const updateQuantity = (prevState, data) => {
  const { cart } = prevState;
  let cartGroup;
  let value;
  let key;
  if (data.orderItem.category) key = data.orderItem.category.replace(" ", "_");
  else if (data.orderItem.car_name) {
    key = `${data.orderItem.car_year}_${data.orderItem.car_name}_${data.orderItem.car_variant}_${data.orderItem.fuel_type}`;
  }
  switch (data.cartGroup) {
    case "Category": {
      cartGroup = cart.category;
      value = "category";
      const index = cartGroup[key]?.items.findIndex(
        ({ id }) => id === data.orderItem.id
      );
      cartGroup[key]?.items.splice(index, 1, data.orderItem);
      break;
    }
    case "Car Model": {
      cartGroup = cart.carModel;
      value = "carModel";
      const index = cartGroup[key]?.items.findIndex(
        ({ id }) => id === data.orderItem.id
      );
      cartGroup[key]?.items.splice(index, 1, data.orderItem);
      break;
    }
    default: {
      cartGroup = cart.partNumbers;
      value = "partNumbers";
      const index = cartGroup.findIndex(({ id }) => id === data.orderItem.id);
      cartGroup.splice(index, 1, data.orderItem);
      return { ...prevState, cart: { ...cart, partNumbers: cartGroup } };
    }
  }

  return { ...prevState, cart: { ...cart, [value]: { ...cartGroup } } };
};

export const globalReducer = (prevState, action) => {
  switch (action.type) {
    case CREATE_ORDER:
      return { ...prevState, ...action.payload };
    case UPDATE_ORDER:
      return {
        ...prevState,
        currentOrder: action.payload.currentOrder,
        currentOrderId: action.payload.currentOrderId,
        cart: action.payload.cart,
        isCartOpen: prevState.isCartOpen && action.payload.isCartOpen
      };
    case UPDATE_ORDER_ITEM:
      return updateQuantity(prevState, action.payload);
    case CART_OPEN:
      return { ...prevState, isCartOpen: true };
    case CART_CLOSE:
      return { ...prevState, isCartOpen: false };
    case RESET_CART:
      return { ...prevState, cart: {}, currentOrder: {}, currentOrderId: null };
    case RESET:
      return INITIAL_STATE;
  }
};
