import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import {
  ORDER_TYPES,
  REGISTRATION_PENDING_STATUS,
  VERIFICATION_PENDING_STATUS,
  VERIFICATION_PENDING_STATUS_DISPLAY,
} from "../../constants";
import useServerAPI from "../../customHooks/useServerAPI";
import { initalizeStateFromCookies, isUndefined } from "../../helpers/utils";
import { useAuthContext } from "../../state/auth";
import { INITIALIZE, UPDATE_USER_SUMMARY } from "../../state/auth/reducer";
import { UPDATE_ORDER } from "../../state/global/actions";
import LoadingSpinner from "../bits/LoadingSpinner";

const LayoutV2 = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [{ userId, initialized }, dispatch] = useAuthContext();
  const router = useRouter();
  const [userSummaryResponse, , getUserSummary] =
    useServerAPI("getUserSummary");
  const [activeOrdersRes, , getActiveOrderAPI] = useServerAPI("getActiveOrder");

  useEffect(() => {
    const initialzeState = async () => {
      dispatch({
        type: INITIALIZE,
        payload: await initalizeStateFromCookies(),
      });
    };
    if (typeof window !== "undefined") {
      initialzeState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userId) {
      getUserSummary({ userId });
      getActiveOrderAPI(ORDER_TYPES[0]);
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (initialized && !userId) {
      setLoading(false);
    }
  }, [initialized, userId]);

  useEffect(() => {
    if (!isUndefined(activeOrdersRes?.order_num)) {
      dispatch({ type: UPDATE_ORDER, payload: activeOrdersRes });
    }
  }, [activeOrdersRes]);

  useEffect(() => {
    if (userSummaryResponse) {
      const user = { ...userSummaryResponse };
      if (
        user.user_state?.name === REGISTRATION_PENDING_STATUS &&
        user.profile_completion_percentage === 100
      ) {
        user.user_state.name = VERIFICATION_PENDING_STATUS;
        user.user_state.user_display_str = VERIFICATION_PENDING_STATUS_DISPLAY;
        setLoading(false);
      } else if (
        user.user_state?.name === REGISTRATION_PENDING_STATUS &&
        !user.business_name
      ) {
        router.push("/register");
      } else {
        setLoading(false);
      }
      dispatch({ type: UPDATE_USER_SUMMARY, payload: user });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSummaryResponse]);
  useEffect(() => {
    if (router.pathname === "/register") {
      setLoading(false);
    }
  }, [router]);

  if (initialized && !loading) return <>{children}</>;
  return (
    <div className="flex justify-center w-full">
      <div className="fixed z-50 flex justify-center w-full h-full max-w-3xl mx-auto opacity-60 bg-gradient-to-r from-primary-100 to-primary-900">
        <LoadingSpinner />
      </div>
    </div>
  );
};

export default LayoutV2;
