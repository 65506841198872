import React from "react";

const TextLink = ({ className, children, variant = "primary", ...props }) => {
  return (
    <span
      className={`font-semibold cursor-pointer ${variant === "primary" ?"text-primary": "text-[#ff5050]" } ${className}`}
      {...props}
    >
      {children}
    </span>
  );
};

export default TextLink;
