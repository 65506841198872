import Image from "next/image";
import React, { useState } from "react";
import CarCard from "../../bits/CarCard";
import Card from "../../bits/Card";
import TextLink from "../../bits/TextLink";
import ProductCard from "../../blocks/ProductCard";

const CartCarGroupView = ({ carList, onClick }) => {
  const [viewedCar, setViewedCar] = useState(null);
  return (
    <>
      {viewedCar && (
        <div className="space-y-2">
          <CarCard
            brand={viewedCar.brand}
            model={viewedCar.name}
            fuelType={viewedCar.fuel_type}
            variant={viewedCar.variant}
            year={viewedCar.start_year}
            imageUrl={viewedCar.image || viewedCar.image_url}
            {...viewedCar}
            hideBackground
          >
            {/* <button onClick={() => {}}>
              <Image
                src="/assets/icons/deleteBold.svg"
                height={30}
                width={30}
                alt="deleteBold"
              />
            </button> */}
          </CarCard>
          <div className="flex justify-between px-3">
            <h2 className="text-lg font-semibold text-black">
              {viewedCar?.items?.length} Parts added
            </h2>
            <button onClick={() => setViewedCar(null)}>
              <Image
                src="/assets/icons/arrowUpCircle.svg"
                height={30}
                width={30}
                alt="deleteBold"
              />
            </button>
          </div>
          {viewedCar?.items.map((product) => (
            <ProductCard
              key={product.id}
              imageUrl={product.image}
              {...product}
            />
          ))}
          <div className="text-right">
            <TextLink className="text-lg" onClick={() => onClick(viewedCar)}>
              Continue Shopping
            </TextLink>
          </div>
        </div>
      )}
      {carList.lenght > 1 && (
        <h2 className="text-lg font-semibold text-black">Other cars</h2>
      )}
      {carList?.map((car, index) =>
        viewedCar?.car_variant_id !== car.car_variant_id ? (
          <Card key={index} variant="dark">
            <CarCard
              brand={car.brand}
              model={car.name}
              fuelType={car.fuel_type}
              variant={car.variant}
              year={car.start_year}
              imageUrl={car.image || car.image_url}
              {...car}
              hideBackground
            />
            <div className="flex justify-between px-3">
              <h2 className="text-[#bdbdbd] flex">
                Parts added
                <span className="mx-2 font-medium text-black">
                  {car?.items.length}
                </span>
                <button onClick={() => setViewedCar(car)}>
                  <Image
                    src="/assets/icons/eye.svg"
                    height={20}
                    width={20}
                    alt="eye"
                  />
                </button>
              </h2>
              <TextLink className="text-lg" onClick={() => onClick(car)}>
                Continue Shopping
              </TextLink>
            </div>
          </Card>
        ) : null
      )}
    </>
  );
};

export default CartCarGroupView;
