import { isNull, isUndefined } from "../../helpers/utils";

export const CREATE_ORDER = "CREATE_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_ITEM = "UPDATE_ORDER_ITEM";
export const CART_OPEN = "CART_OPEN";
export const CART_CLOSE = "CART_CLOSE";
export const RESET_CART = "RESET_CART";
export const RESET = "RESET";

export const updateTitle = (title) => {
  return { type: "UPDATE_TITLE", payload: title };
};

export const openCart = () => {
  return {
    type: CART_OPEN,
  };
};

export const closeCart = () => {
  return {
    type: CART_CLOSE,
  };
};

export const addOrder = (orderResponse) => {
  return {
    type: CREATE_ORDER,
    payload: { currentOrder: orderResponse, currentOrderId: orderResponse.id },
  };
};

export const groupOrderItems = (order_items = []) => {
  const cart = {
    partNumbers: [],
    carModel: {},
    category: {},
  };
  order_items?.forEach((item) => {
    if (!isNull(item.car_name) && !isNull(item.car_variant)) {
      const carKey = `${item.car_year}_${item.car_name}_${item.car_variant}_${item.fuel_type}`;

      if (isUndefined(cart.carModel[carKey])) {
        const [brand, name, car_variant_id] = item.car_name.split("_");
        cart.carModel[carKey] = {
          brand,
          name,
          car_variant_id,
          image_url: item.car_variant_url,
          variant: item.car_variant,
          fuel_type: item.fuel_type,
          start_year: item.car_year,
          items: [item],
        };
      } else {
        cart.carModel[carKey]?.items.push(item);
      }
    } else if (!isNull(item.category)) {
      const [category, subCategory, filter] = item.category.split("_");
      if (isUndefined(cart.category[item.category])) {
        cart.category[item.category] = {
          category,
          subCategory,
          filter,
          items: [item],
        };
      } else {
        cart.category[item.category]?.items.push(item);
      }
    } else {
      cart.partNumbers.push(item);
    }
  });
  return cart;
};

export const updateOrder = (orderResponse) => {
  const { order_items, ...orderDetails } = orderResponse;
  const cart = groupOrderItems(order_items || []);

  return {
    type: UPDATE_ORDER,
    payload: {
      currentOrder: { ...orderDetails, total_items: order_items.length },
      currentOrderId: orderResponse.id,
      cart,
      isCartOpen: order_items.length > 0,
    },
  };
};

export const updateOrderQuantity = (orderItem, cartGroup) => {
  return {
    type: UPDATE_ORDER_ITEM,
    payload: { cartGroup, orderItem },
  };
};

export const reset = () => {
  return {
    type: RESET,
  };
};
