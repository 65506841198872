import { useState } from "react";

import { isUndefined } from "../helpers/utils";
import * as MasterService from "../services/masterService";

const useServerAPI = (apiType) => {
  // apiType: apiType of the API call
  // eg. apiType="getCustomerWriteOffs", data={}
  const [response, setResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const callAPI = async (data) => {
    // data: any data that needs to be passed to API.
    setIsLoading(true);
    try {
      if (isUndefined(MasterService[apiType])) {
        setResponse({ status: "error", notes: "Missing type of API" });
      }
      const response = await MasterService[apiType](data);
      setResponse(response);
    } catch (err) {
      setResponse(err);
    }
    setIsLoading(false);
  };
  return [response, isLoading, callAPI];
};

export default useServerAPI;
