import React, { useEffect, useMemo, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { toast } from "react-toastify";

import useServerAPI from "../../../customHooks/useServerAPI";
import {
  getCookieItem,
  getIsFieldExecutive,
  isUndefined,
  priceFormatter,
  setCookieItem,
} from "../../../helpers/utils";
import { useStateContext } from "../../../state/global";
import {
  addOrder,
  updateOrder,
  updateOrderQuantity,
} from "../../../state/global/actions";
import { ORDER_TYPES } from "../../../constants";
import Button from "../../bits/Button";
import QuantityChanger from "../QuantityChanger";

const ProductCard = ({
  part_num,
  part_name,
  manufacturer,
  lot_size,
  amount,
  discounted_price,
  sku_id,
  selectedCategory,
  imageUrl,
  disabled = false,
  activeCar,
  activeCategory,
  activeCategoryFilter,
  extraDesc,
  ...productDetails
}) => {
  const { query } = useRouter();
  const [{ cart, currentOrder, isCartOpen }, dispatch] = useStateContext();
  const isFieldExecutive = getIsFieldExecutive();

  const productInCart = useMemo(() => {
    if (isUndefined(cart)) {
      return;
    }
    let currentProduct;
    if (Object.values(cart.category).length > 0) {
      Object.values(cart.category).some(({ items }) => {
        if (items.find((item) => item.sku_id === sku_id))
          currentProduct = items.find((item) => item.sku_id === sku_id);
      });
    }
    if (
      isUndefined(currentProduct) &&
      Object.values(cart.carModel).length > 0
    ) {
      Object.values(cart.carModel).forEach(({ items }) => {
        if (items.find((item) => item.sku_id === sku_id))
          currentProduct = items.find((item) => item.sku_id === sku_id);
      });
    }
    if (isUndefined(currentProduct) && cart?.partNumbers?.length > 0) {
      currentProduct = cart.partNumbers.find((item) => item.sku_id === sku_id);
    }
    return currentProduct;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, selectedCategory, sku_id]);

  const productQuantity = productInCart?.quantity || 0;
  const isProductAlreadyInCart = productQuantity > 0;
  const orderId =
    currentOrder?.order_type === query?.category && currentOrder?.id;

  const selectedCustomer = JSON.parse(getCookieItem("selectedCustomer") || "{}");
  const selectedDistributor = JSON.parse(
    getCookieItem("selectedDistributor") || "{}"
  );

  // State
  const [isEditingInProgress, setIsEditingInProgress] = useState(false);
  const [quantity, setQuantity] = useState(productQuantity || 1);

  // API hooks
  const [orderResponse, orderItemAdding, addOrderItem] =
    useServerAPI("addOrderItem");
  const [getActiveOrderRes, , getActiveOrder] = useServerAPI("getActiveOrder");
  const [createOrderResponse, , createOrder] = useServerAPI("createOrder");
  const [updateOrderItemResponse, updateInProgress, updateOrderItem] =
    useServerAPI("updateOrderItem");
  const [deleteRes, , deleteOrderItem] = useServerAPI("deleteOrderItem");

  // Functions
  const addOrderToCart = (order) => {
    let data = {
      sku_id,
      quantity,
      order,
    };
    if (activeCar) {
      const car_name = `${activeCar.brand}_${activeCar.name}_${activeCar.car_variant_id}`;
      data = {
        ...data,
        car_name,
        car_variant: activeCar.variant,
        car_variant_id: parseInt(activeCar.car_variant_id),
        fuel_type: activeCar.fuel_type,
        car_year: activeCar.start_year,
      };
    } else if (activeCategory) {
      const category = `${activeCategory.name}_${activeCategory.subCategory}_${activeCategoryFilter}`;
      data = {
        ...data,
        category,
      };
    }
    addOrderItem(data);
  };
  const handleAddToCart = () => {
    const data = { order_type: query.category, order_items: [] };
    if (isFieldExecutive) {
      data.user_id = selectedCustomer.id;
      data.fulfilled_by_id = selectedDistributor.id;
    }
    !orderId ? createOrder(data) : addOrderToCart(orderId);
  };

  const onQuantityUpdate = () => {
    if (quantity === productQuantity) {
      setIsEditingInProgress(false);
    } else if (quantity > 0) {
      updateOrderItem({ quantity, orderItemId: productInCart.id });
    } else {
      onDelete();
    }
  };

  const onDelete = () => {
    deleteOrderItem({ orderItemId: productInCart.id });
    setQuantity(1);
  };

  useEffect(() => {
    if (deleteRes === "") {
      toast.success("Item removed to cart");
      getActiveOrder(ORDER_TYPES[0]);
    } else if (deleteRes?.message) {
      toast.error(deleteRes?.message);
    }
  }, [deleteRes]);

  // Life cycle Methods
  useEffect(() => {
    if (!isUndefined(createOrderResponse?.id)) {
      dispatch(addOrder(createOrderResponse));
      setCookieItem("orderId", createOrderResponse?.id);
      setCookieItem("orderType", createOrderResponse?.order_type);
      addOrderToCart(createOrderResponse?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderResponse]);

  useEffect(() => {
    if (orderResponse?.id) {
      toast.success("Item added to cart");
      dispatch(updateOrder(orderResponse));
    } else if (!isUndefined(orderResponse?.error)) {
      toast.error("Unable to add item in Cart");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderResponse]);

  useEffect(() => {
    if (getActiveOrderRes) {
      dispatch(updateOrder(getActiveOrderRes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActiveOrderRes]);

  useEffect(() => {
    if (updateOrderItemResponse) {
      setIsEditingInProgress(false);
      toast.success("Quantity Updated");
      getActiveOrder(ORDER_TYPES[0]);
      dispatch(updateOrderQuantity(updateOrderItemResponse, selectedCategory));
    } else if (updateOrderItemResponse?.error) {
      toast.error("Quantity Not Updated");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOrderItemResponse]);

  return (
    <div className="w-full p-4 bg-[#fbfbfb] border rounded-lg">
      <div className="flex grow">
        <span className="inline-flex w-20 h-20 sm:hidden">
          {imageUrl ? (
            <Image src={imageUrl} height={102} width={102} alt={part_name} />
          ) : (
            <Image
              src="/assets/images/dummyProduct.png"
              height={66}
              width={66}
              alt={part_name}
            />
          )}
        </span>
        <div className="flex flex-col justify-between w-full mb-5 sm:flex-row">
          <div className="inline-flex space-x-6">
            <span className="hidden w-20 h-20 sm:inline-block">
              {imageUrl ? (
                <Image
                  src={imageUrl}
                  height={102}
                  width={102}
                  alt={part_name}
                />
              ) : (
                <Image
                  src="/assets/images/dummyProduct.png"
                  height={66}
                  width={66}
                  alt={part_name}
                />
              )}
            </span>
            <p className="text-base font-semibold leading-tight break-words sm:text-lg text-ellipsis">
              <span className="sm:text-sm font-normal text-[#bdbdbd]">
                {part_num}
              </span>
              <br />
              {part_name?.replaceAll(",", ", ")} {extraDesc}
            </p>
          </div>
          <p className="ml-6 font-semibold sm:text-right sm:ml-0">
            <span className="text-sm font-normal text-[#bdbdbd]">
              By {manufacturer}
            </span>
            <br />
            {!!discounted_price && (
              <span className="mr-2 text-xs font-normal text-[#bdbdbd] line-through">
                {priceFormatter.format(amount)}
              </span>
            )}
            {priceFormatter.format(discounted_price || amount)}
          </p>
        </div>
      </div>
      {disabled ? (
        <div className="flex justify-between">
          <div className="flex space-x-2.5">
            <p className="text-[#bdbdbd]">Qty</p>
            <p className="font-semibold">{productDetails.quantity}</p>
            {lot_size > 1 && <p> X Pack of {lot_size}</p>}
          </div>
        </div>
      ) : !isProductAlreadyInCart ? (
        <div className="flex justify-between">
          <QuantityChanger
            quantity={quantity}
            setQuantity={setQuantity}
            lotSize={lot_size}
          />
          <Button
            disabled={orderItemAdding}
            variant="sm"
            onClick={handleAddToCart}
            className="flex items-center justify-center w-16 h-8 space-x-1 font-normal"
          >
            <span>+</span>
            <Image
              src="/assets/icons/cart.svg"
              height={22}
              width={22}
              alt="cart"
            />
          </Button>
        </div>
      ) : isEditingInProgress ? (
        <div className="flex justify-between">
          <QuantityChanger
            quantity={quantity}
            setQuantity={setQuantity}
            lotSize={lot_size}
          />
          <div className="flex ">
            <button onClick={onDelete}>
              <Image
                src="/assets/icons/deleteBold.svg"
                height={22}
                width={22}
                alt="deleteBold"
              />
            </button>
            <button
              className="rounded-lg flex items-center h-8 justify-center ml-8 w-16 font-bold !text-primary !bg-white border-2 border-primary"
              onClick={onQuantityUpdate}
            >
              {updateInProgress ? "Saving" : "Save"}
            </button>
          </div>
        </div>
      ) : (
        <div className="flex justify-between">
          <div className="flex flex-col sm:flex-row">
            <div className="flex space-x-2.5">
              <p className="text-[#bdbdbd]">Qty</p>
              <p className="font-semibold">{productQuantity}</p>
              <span
                className="mr-2 cursor-pointer"
                onClick={() => setIsEditingInProgress(true)}
              >
                <Image
                  src="/assets/icons/edit.svg"
                  height={22}
                  width={22}
                  alt="edit"
                />
              </span>
            </div>
            {lot_size > 1 && <p className="px-2"> X Pack of {lot_size}</p>}
          </div>
          <div className="flex">
            <button onClick={onDelete}>
              <Image
                src="/assets/icons/deleteBold.svg"
                height={22}
                width={22}
                alt="deleteBold"
              />
            </button>
            {!isCartOpen && (
              <span className="flex items-center ml-4 rounded-lg bg-[#f27a21] px-3 text-[#f27a21] bg-opacity-30">
                Added To Cart
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCard;
