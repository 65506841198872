import React, { useState } from "react";

const Dropdown = ({
  options = [],
  placeholder,
  onChange,
  intialValue,
  className = "",
}) => {
  const [value, setValue] = useState(intialValue);

  return (
    <select
      className={
        "px-2.5 py-1 text-lg text-[#bdbdbd] border rounded-lg" + className
      }
      onChange={(event) => {
        setValue(event.target.value);
        onChange(event.target.value);
      }}
      value={value}
    >
      <option value={""}>{placeholder}</option>
      {options?.map((option) =>
        typeof option === "string" ? (
          <option key={option} value={option}>
            {option}
          </option>
        ) : (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        )
      )}
    </select>
  );
};

export default Dropdown;
