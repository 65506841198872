import React, { useEffect, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";

import BulkOrder from "../../components/container/BulkOrderPage";
import { ORDER_TYPES } from "../../constants";
import ProductSearch from "../../components/container/ProductSearch";
import useServerAPI from "../../customHooks/useServerAPI";
import { useStateContext } from "../../state/global";
import { updateOrder } from "../../state/global/actions";
import CarSearch from "../../components/container/CarSearch";
import Tabs from "../../components/bits/Tabs";
import CategorySearch from "../../components/container/CategorySearch";
import {
  getCookieItem,
  getIsFieldExecutive,
  isNull,
  isUndefined,
} from "../../helpers/utils";

export const CATEGORIES = ["Part Number", "Car Model", "Category"];

const ProductPage = ({ categories }) => {
  const { query } = useRouter();
  const [, dispatch] = useStateContext();
  const [searchCategory, setSearchCategory] = useState(
    !query.bulk ? CATEGORIES[0] : ""
  );
  const [activeCar, setActiveCar] = useState(null);
  const [carSelectionActive, setCarSelectionActive] = useState(true);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeCategoryFilter, setActiveCategoryFilter] = useState();
  const [carGroupViewActive, setCarGroupViewActive] = useState(false);
  const selectedCustomer = JSON.parse(getCookieItem("selectedCustomer"));
  const isFieldExecutive = getIsFieldExecutive();

  const [activeOrderResponse, , getActiveOrder] =
    useServerAPI("getActiveOrder");

  useEffect(() => {
    if (!query.bulk && (!isFieldExecutive || selectedCustomer?.id)) {
      getActiveOrder(ORDER_TYPES[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.bulk, selectedCustomer?.id]);

  useEffect(() => {
    if (!isUndefined(activeOrderResponse?.order_num)) {
      dispatch(updateOrder(activeOrderResponse));
    }
  }, [activeOrderResponse, dispatch]);

  return (
    <>
      <Head>
        <title>{query.category}</title>
      </Head>
      {query.bulk ? (
        <BulkOrder pageTitle={query.category} initalCategory={query.category} />
      ) : (
        <>
          <Tabs
            list={categories}
            selectedTab={searchCategory}
            setSelectedTab={(tab) => {
              setCarSelectionActive(isNull(activeCar));
              setCarGroupViewActive(false);
              setSearchCategory(tab);
            }}
          />
          <div className="p-4 space-y-3 overflow-y-scroll">
            {searchCategory === CATEGORIES[0] && (
              <ProductSearch placeholder="Search by Parts Number" />
            )}
            {searchCategory === CATEGORIES[1] && (
              <>
                <CarSearch
                  activeCar={activeCar}
                  setActiveCar={setActiveCar}
                  carSelectionActive={carSelectionActive}
                  setCarSelectionActive={setCarSelectionActive}
                  carGroupViewActive={carGroupViewActive}
                  setCarGroupViewActive={setCarGroupViewActive}
                />
                {!carSelectionActive && !carGroupViewActive && (
                  <ProductSearch
                    activeCar={activeCar}
                    placeholder="Search Parts here"
                  />
                )}
              </>
            )}
            {searchCategory === CATEGORIES[2] && (
              <>
                <CategorySearch
                  selected={activeCategory}
                  onSelect={setActiveCategory}
                  activeCategoryFilter={activeCategoryFilter}
                  setActiveCategoryFilter={setActiveCategoryFilter}
                />
                {!isNull(activeCategory) && (
                  <ProductSearch
                    activeCategory={activeCategory}
                    activeCategoryFilter={activeCategoryFilter}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export async function getStaticPaths() {
  const paths = ORDER_TYPES.map((path) => ({
    params: { category: path },
  }));
  return {
    paths,
    fallback: false, // can also be true or 'blocking'
  };
}

export async function getStaticProps(context) {
  try {
    const categories =
      context.params.category === ORDER_TYPES[0]
        ? CATEGORIES
        : CATEGORIES.slice(0, 2);
    return {
      props: {
        categories,
      }, // will be passed to the page component as props
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}

export default ProductPage;
