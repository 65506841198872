import React, { useState } from "react";

const InputGroup = ({
  label,
  id,
  maxLength,
  handleChange,
  value,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const onChange = (event) => {
    if (maxLength != undefined) {
      event.target.value = event.target.value.substring(0, maxLength);
    }
    if (handleChange) {
      handleChange(event);
    } else {
      setInputValue(event.target.value);
    }
  };

  return (
    <div className={`text-left ${label ? "mt-3" : "mt-1"}`}>
      {label ? (
        <label className="font-semibold" htmlFor={id}>
          {label}
        </label>
      ) : null}
      <input
        id={id}
        maxLength={maxLength}
        value={value || inputValue}
        onChange={onChange}
        className="w-full px-3 py-2 mt-2 border rounded-lg"
        {...props}
      />
    </div>
  );
};

export default InputGroup;
