import axios from "axios";

import queryStringBuilder from "./queryStringBuilder";
import { getAuthToken } from "./utils";

export const httpRequest = ({
  method = "get",
  url = "",
  queryParams = {},
  data = {},
  withAuthToken = false,
  options = {},
}) => {
  const queryString = queryStringBuilder(queryParams);
  const combinedUrl = `${url}${queryString}`;

  return new Promise(async (resolve, reject) => {
    if (withAuthToken) {
      const userToken = await getAuthToken();
      options = {
        headers: {
          Authorization: "Token " + userToken,
        },
      };
    }

    axios[method](
      combinedUrl,
      method === "get" || method === "delete" ? options : data,
      options
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          reject({
            message: "Unauthorized",
            error: error.response,
          });
        } else if (error.response && error.response.status === 400) {
          reject({
            message:
              "Missing data, Please make sure all values are filled correctly.",
            error: error.response,
          });
        } else if (
          error.response &&
          (error.response.status === 500 || error.response.status === 502)
        ) {
          reject({
            message:
              "Something went wrong with our servers, Please try after sometime.",
            error: error.response,
          });
        } else {
          reject({
            message:
              "Something went wrong with our servers, Please try after sometime.",
          });
        }
      });
  });
};
