import React, { useMemo, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import TextLink from "../components/bits/TextLink";
import { isUndefined } from "../helpers/utils";

export const SCREENS = [
  {
    height: "256",
    width: "294",
    image: "/assets/images/transparency.png",
    alt: "transparency",
    description: "Complete transparency - Discounts & pricing",
  },
  {
    height: "256",
    width: "301",
    image: "/assets/images/support.png",
    alt: "support",
    description: "Order Tracking and track ETD",
  },
  {
    height: "327",
    width: "302",
    image: "/assets/images/tracking.png",
    alt: "tracking",
    description: "Best support services (dedicated ASM)",
  },
];
const SplashScreen = () => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const isLastSlide = sliderIndex + 1 === SCREENS.length;
  const isFirstSlide = sliderIndex === 0;
  const currentScreen = useMemo(() => SCREENS[sliderIndex], [sliderIndex]);
  return (
    <div className="flex flex-col justify-between w-full h-screen px-4 py-5">
      <div className="text-end">
        {!isLastSlide && (
          <Link href="/login">
            <TextLink>Skip</TextLink>
          </Link>
        )}
      </div>
      <div className="flex justify-between">
        {!isFirstSlide && (
          <span
            className="font-semibold cursor-pointer"
            onClick={() => setSliderIndex((index) => index - 1)}
          >
            Back
          </span>
        )}
        <span />
        <div className="absolute top-0 left-0 flex flex-col items-center justify-between w-full h-full -z-10 py-7">
          <span />
          <Image
            src={currentScreen.image}
            width={currentScreen.width}
            height={currentScreen.height}
            alt={currentScreen.alt}
          />
          <h4>{currentScreen.description}</h4>
          <div className="flex space-x-2">
            {SCREENS.map((screen, index) =>
              index !== sliderIndex ? (
                <span
                  key={screen.alt}
                  className="block w-2 h-2 bg-black cursor-pointer bg-opacity-20 rounded-xl"
                  onClick={() => setSliderIndex(index)}
                />
              ) : (
                <span
                  key={screen.alt}
                  className="block w-5 h-2 bg-primary rounded-xl"
                />
              )
            )}
          </div>
        </div>
        <TextLink
          onClick={() => !isLastSlide && setSliderIndex((index) => index + 1)}
        >
          {isLastSlide ? <Link href="/login">Get Started</Link> : "Next"}
        </TextLink>
      </div>
    </div>
  );
};

export const getServerSideProps = async ({ req, query }) => {
  const { cookies } = req;
  if (isUndefined(query.redirect) && cookies.userId && cookies.userToken) {
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    };
  }
  return {
    props: {},
  };
};

export default SplashScreen;
