import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ADD_ADDRESS_FIELDS } from "../../../constants/registrationForm";
import useServerAPI from "../../../customHooks/useServerAPI";
import {
  clearCookieItem,
  getCookieItem,
  getIsFieldExecutive,
  isUndefined,
  priceFormatter,
} from "../../../helpers/utils";
import { CATEGORIES } from "../../../pages/products/[category]";
import { BILLING } from "../../../pages/profile";
import { useStateContext } from "../../../state/global";
import { closeCart, reset } from "../../../state/global/actions";
import Button from "../../bits/Button";
import CarCard from "../../bits/CarCard";
import Modal from "../../bits/Modal";
import Tabs from "../../bits/Tabs";
import TextLink from "../../bits/TextLink";
import Form from "../../blocks/Form";
import ProductCard from "../../blocks/ProductCard";
const categories = [];

const Cart = () => {
  const router = useRouter();
  const [searchCategory, setSearchCategory] = useState();
  const [placeOrderClicked, setPlaceOrderClicked] = useState(router.query.bulk);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [userAddresses, setUserAddresses] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [{ cart, currentOrderId, currentOrder }, dispatch] = useStateContext();
  const [userAddressesRes, , getUserAddresses] =
    useServerAPI("getUserAddresses");
  const [orderResponse, isPlaceOrderInProgress, placeOrder] =
    useServerAPI("placeOrder");
  const [addAddressResponse, , addUserAddress] = useServerAPI("addUserAddress");
  const isFieldExecutive = getIsFieldExecutive();

  const selectedCustomer = JSON.parse(
    getCookieItem("selectedCustomer") || "{}"
  );
  const selectedDistributor = JSON.parse(
    getCookieItem("selectedDistributor") || "{}"
  );

  if (cart.partNumbers?.length && !categories.includes(CATEGORIES[0]))
    categories.push(CATEGORIES[0]);
  if (Object.keys(cart.carModel)?.length && !categories.includes(CATEGORIES[1]))
    categories.push(CATEGORIES[1]);
  if (Object.keys(cart.category)?.length && !categories.includes(CATEGORIES[2]))
    categories.push(CATEGORIES[2]);

  useEffect(() => {
    getUserAddresses();
    setSearchCategory(categories[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userAddressesRes?.length > 1) {
      const addresses = userAddressesRes.filter(
        ({ address_type }) => address_type !== BILLING
      );
      setUserAddresses(addresses);
      setSelectedAddress(addresses[0].id);
    }
  }, [userAddressesRes]);

  useEffect(() => {
    if (orderResponse?.id) {
      toast.success(`Order placed ${orderResponse.order_num}`);
      clearCookieItem("orderType");
      clearCookieItem("orderId");
      clearCookieItem("selectedCustomer");
      clearCookieItem("selectedDistributor");
      dispatch(closeCart());
      dispatch(reset());
      const userId = getCookieItem("userId");
      const userToken = getCookieItem("userToken");
      router.push(`/?userId=${userId}&userToken=${userToken}`, "/");
    }
  }, [dispatch, orderResponse, router]);
  useEffect(() => {
    if (!isUndefined(addAddressResponse?.id)) {
      setSelectedAddress(addAddressResponse.id);
      setUserAddresses((prevAdresses) => [...prevAdresses, addAddressResponse]);
      setShowModal(false);
    }
  }, [addAddressResponse]);

  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          setShow={setShowModal}
          // onCloseModal={closeModal}
        >
          <h1 className="mb-4 text-xl font-semibold text-primary">
            Add New Address
          </h1>
          <Form
            buttonText="Continue"
            fields={ADD_ADDRESS_FIELDS}
            onSubmit={(formValues) => {
              addUserAddress({ ...formValues, address_type: "shipping" });
            }}
            initialValues={{}}
          />
        </Modal>
      )}
      <div
        className="absolute flex items-end w-full top-0 h-screen max-w-3xl z-20 backdrop-blur-[2px] bg-opacity-5"
        onClick={() => dispatch(closeCart())}
      >
        <div
          className={`w-full mx-0.5 bg-white ${
            isFieldExecutive && placeOrderClicked ? "space-y-4" : "h-4/5"
          } flex flex-col justify-between rounded-t-3xl shadow-2xl px-5 py-4`}
          onClick={(e) => e.stopPropagation()}
        >
          {placeOrderClicked ? (
            <>
              {isFieldExecutive ? (
                <>
                  <h1 className="text-2xl font-bold">Summary</h1>
                  <div className="p-2.5 border border-black rounded mb-8">
                    <h2>
                      <span className="font-semibold">Order Value:</span>{" "}
                      {priceFormatter.format(currentOrder.total_amount)}
                    </h2>
                    <h2>
                      <span className="font-semibold">Customer:</span>{" "}
                      {selectedCustomer?.name}
                    </h2>
                    <h2>
                      <span className="font-semibold">Phone:</span>{" "}
                      {selectedCustomer?.phone}
                    </h2>
                    <h2>
                      <span className="font-semibold">Distributor:</span>{" "}
                      {selectedDistributor?.name}
                    </h2>
                  </div>
                </>
              ) : (
                <div>
                  <h2 className="text-xl font-semibold">
                    Select shipping address
                  </h2>

                  <div className="my-4 space-y-2 overflow-auto h-5/6">
                    {userAddresses?.map((address) => (
                      <div
                        key={address.alias}
                        className={`p-3 border rounded-lg bg-slate-50 cursor-pointer ${
                          selectedAddress === address.id
                            ? "border-2 border-primary"
                            : ""
                        }`}
                        onClick={() => setSelectedAddress(address.id)}
                      >
                        <h2 className="mb-1.5 font-semibold">
                          {address.alias}
                        </h2>
                        <p className="text-sm font-normal text-[#bdbdbd]">
                          {address.address1}
                        </p>
                        <p className="text-sm font-normal text-[#bdbdbd]">
                          {address.address2}
                        </p>
                      </div>
                    ))}
                    <TextLink
                      className="float-right"
                      onClick={() => setShowModal(true)}
                    >
                      + Add Address
                    </TextLink>
                  </div>
                </div>
              )}
              <Button
                disabled={isPlaceOrderInProgress}
                onClick={() =>
                  isFieldExecutive
                    ? placeOrder({
                        id: currentOrderId,
                        shipping_address_id: selectedCustomer.address[0]?.id ?? selectedCustomer.address?.id,
                      })
                    : placeOrder({
                        id: currentOrderId,
                        shipping_address_id: selectedAddress,
                      })
                }
              >
                Confirm and place Order
              </Button>
            </>
          ) : (
            <>
              <div className="flex justify-between text-lg font-semibold">
                <h2>
                  Cart{"  "}
                  <span className="text-base font-normal text-[#bdbdbd]">
                    ({currentOrder.total_items} items)
                  </span>
                </h2>
                <h2>{priceFormatter.format(currentOrder.total_amount)}</h2>
              </div>
              <Tabs
                list={categories}
                selectedTab={searchCategory}
                setSelectedTab={setSearchCategory}
              />
              <div className="overflow-auto h-5/6">
                {searchCategory === CATEGORIES[0] && (
                  <div className="space-y-2.5">
                    {cart?.partNumbers?.map((e) => (
                      <ProductCard key={e.id} imageUrl={e.image} {...e} />
                    ))}
                  </div>
                )}
                {searchCategory === CATEGORIES[1] &&
                  Object.entries(cart.carModel).map(
                    ([key, { items, ...car }]) => (
                      <div className="mb-3 space-y-2" key={key}>
                        <CarCard
                          brand={car.brand}
                          model={car.name}
                          fuelType={car.fuel_type}
                          variant={car.variant}
                          year={car.start_year}
                          imageUrl={car.image || car.image_url}
                          {...car}
                          itemCount={items.length}
                          hideBackground
                        />
                        {items.map((e) => (
                          <ProductCard
                            key={e.id}
                            imageUrl={e.image}
                            {...e}
                            selectedCategory={searchCategory}
                          />
                        ))}
                      </div>
                    )
                  )}
                {searchCategory === CATEGORIES[2] &&
                  Object.entries(cart.category).map(
                    ([key, { category, items }]) => (
                      <div className="mb-3 space-y-2" key={key}>
                        <div className="flex justify-between">
                          <h2 className="font-semibold">{category}</h2>
                          <p className="text-sm font-normal text-[#bdbdbd]">
                            {items.length} items
                          </p>
                        </div>
                        {items.map((e) => (
                          <ProductCard
                            key={e.id}
                            imageUrl={e.image}
                            {...e}
                            selectedCategory={searchCategory}
                          />
                        ))}
                      </div>
                    )
                  )}
              </div>
              <Button onClick={() => setPlaceOrderClicked(true)}>
                Place Order
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Cart;
