import React, { useEffect, useMemo, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import Cart from "../../container/Cart";
import { useStateContext } from "../../../state/global";
import { closeCart, openCart, reset } from "../../../state/global/actions";
import LoadingSpinner from "../../bits/LoadingSpinner";
import useServerAPI from "../../../customHooks/useServerAPI";
import {
  clearCookieItems,
  getCookieItem,
  getIsFieldExecutive,
  isNull,
  setAuthToken,
  setCookieItem,
} from "../../../helpers/utils";
import TextLink from "../../bits/TextLink";
import Head from "next/head";
import { ACCOUNT_VERIFIED_STATUS } from "../../../constants";
import SideMenuBar from "../../container/SideMenuBar";
import { useAuthContext } from "../../../state/auth";
import { UPDATE_USER, UPDATE_USER_ID } from "../../../state/auth/reducer";
import ChangePasswordModal from "../../blocks/ChangePasswordModal";

const BOTTOM_TABS = [
  {
    label: "Dashboard",
    imageUrl: "/assets/icons/home.svg",
    selectedImageUrl: "/assets/icons/homeBlue.svg",
    imageAlt: "home",
    path: "/",
  },
  {
    label: "My Orders",
    imageUrl: "/assets/icons/orders.svg",
    selectedImageUrl: "/assets/icons/ordersBlue.svg",
    imageAlt: "orders",
    path: "/orders",
  },
  {
    label: "Payments",
    imageUrl: "/assets/icons/payment.svg",
    selectedImageUrl: "/assets/icons/paymentBlue.svg",
    imageAlt: "payments",
    path: "/payments",
  },
  {
    label: "Profile",
    imageUrl: "/assets/icons/profile.svg",
    selectedImageUrl: "/assets/icons/profileBlue.svg",
    imageAlt: "profile",
    path: "/profile",
  },
];

const PageLayout = ({ children }) => {
  const router = useRouter();
  const { pathname, push, query, route } = router;

  const [{ isCartOpen, currentOrder }, dispatch] = useStateContext();
  const [, authDispatch] = useAuthContext();

  const isNotificationScreen = pathname === "/notifications";
  const isDashboardScreen = pathname === "/";
  const isProfileScreen = pathname === "/profile";

  const userId = getCookieItem("userId");
  const userToken = getCookieItem("userToken");
  const isASMUser = getCookieItem("isASMUser");
  const isFieldExecutive = getIsFieldExecutive();

  const title = useMemo(() => {
    if (route.includes("products"))
      return (!query.bulk && query.category) || "New Order";
    else if (pathname === "/profile") return "Profile";
    else if (isNotificationScreen) return "Notifications";
    else if (pathname === "/payments") return "Payments";
    else if (route.includes("orders")) {
      if (route.includes("item")) return `Items ${query.status}`;
      return "My Orders";
    }
    return "V-motive";
  }, [route, query, pathname, isNotificationScreen]);

  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isSideMenuOpened, setIsSideMenuOpened] = useState(false);
  const [changePassowrdActive, setChangePassowrdActive] = useState(false);

  const [userResponse, , getUser] = useServerAPI("getUser");

  const onCartView = () => {
    dispatch(openCart());
  };

  const logout = async () => {
    const asmDetailsCookie = getCookieItem("asmDetails");
    dispatch(reset());
    if (!isNull(asmDetailsCookie)) {
      const asmDetails = JSON.parse(asmDetailsCookie);
      clearCookieItems();
      setAuthToken(asmDetails.userToken);
      setCookieItem("userId", asmDetails.id);
      setCookieItem("isASMUser", true);
      authDispatch({ type: UPDATE_USER_ID, payload: asmDetails.id });
      router.push(
        `/asm?isASMUser=true&userId=${asmDetails.id}&userToken=${asmDetails.userToken}`,
        "/asm"
      );
    } else {
      clearCookieItems();
      authDispatch(reset());
      router.push("/splash?redirect=false", "/splash");
    }
  };

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsLoading(true);
      dispatch(closeCart());
      setIsSideMenuOpened(false);
    };
    const handleRouteChangeComplete = () => {
      setIsLoading(false);
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [dispatch, router.events]);

  useEffect(() => {
    if (userId) {
      getUser({ userId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (userResponse?.user_state?.name === ACCOUNT_VERIFIED_STATUS) {
      setIsApproved(true);
      authDispatch({ type: UPDATE_USER, payload: userResponse });
    }
  }, [authDispatch, userResponse]);
  useEffect(() => {
    if (isASMUser && pathname !== "/asm") {
      push("/asm");
    } else if (pathname === "/asm" && !isASMUser) {
      push("/");
    }
  }, [isASMUser, pathname, push]);
  switch (pathname) {
    case "/register":
    case "/splash":
    case "/login":
    case "/terms":
      return (
        <div className="h-screen max-w-3xl mx-auto">
          <Head>
            <title>{title}</title>
          </Head>
          {isLoading && (
            <div className="fixed z-50 flex justify-center w-full h-full max-w-3xl opacity-40 bg-gradient-to-r from-primary-100 to-primary-900">
              <LoadingSpinner />
            </div>
          )}
          {children}
        </div>
      );
    default:
      return (
        <div className="flex flex-col max-w-3xl mx-auto">
          <Head>
            <title>{title}</title>
          </Head>
          <div className="fixed z-[55] flex justify-between w-full max-w-3xl px-3 py-4 bg-white">
            {pathname !== "/" && pathname !== "/asm" ? (
              <span className="cursor-pointer" onClick={() => router.back()}>
                <Image
                  src="/assets/icons/leftArrowBlack.svg"
                  height={30}
                  width={30}
                  alt="leftArrowBlack"
                />
              </span>
            ) : (
              <span
                className="cursor-pointer"
                onClick={() => {
                  setIsSideMenuOpened(true);
                }}
              >
                <Image
                  src="/assets/icons/hamburger.svg"
                  height={30}
                  width={30}
                  alt="hamburger"
                />
              </span>
            )}
            {pathname === "/orders" && !isFieldExecutive ? (
              <select
                className="font-bold text-left"
                value={query.category === "Secondary" ? "secondary" : "orders"}
                onChange={(ev) =>
                  push(
                    ev.target.value === "orders"
                      ? "/orders"
                      : "/orders?tab=orders&category=Secondary"
                  )
                }
              >
                <option value="orders">My Orders</option>
                <option value="secondary">Orders Received</option>
              </select>
            ) : (
              <h2 className="text-lg font-semibold capitalize">{title}</h2>
            )}
            <div
              className={`flex justify-between ${
                !isNotificationScreen && !isProfileScreen && "w-24"
              }`}
            >
              {isProfileScreen || pathname === "/asm" ? (
                <TextLink onClick={logout}>Logout</TextLink>
              ) : (
                <>
                  <a href="tel:+919311962746">
                    <Image
                      src="/assets/icons/customer-support.svg"
                      height={30}
                      width={30}
                      alt="help"
                    />
                  </a>
                  {!pathname.includes("/products") && !isNotificationScreen && (
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() => router.push("/notifications")}
                    >
                      <Image
                        src="/assets/icons/notification.svg"
                        height={30}
                        width={30}
                        alt="notification"
                      />
                    </span>
                  )}
                  {pathname.includes("/products") && (
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() =>
                        !query.bulk &&
                        currentOrder?.total_items > 0 &&
                        onCartView()
                      }
                    >
                      <Image
                        src="/assets/icons/cartLight.svg"
                        height={30}
                        width={30}
                        alt="Cart"
                      />
                      {!query.bulk && currentOrder?.total_items > 0 && (
                        <span className="absolute top-1.5 right-0 text-xs text-white rounded-full bg-primary h-5 w-5 p-0.5 text-center">
                          {currentOrder?.total_items}
                        </span>
                      )}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          {changePassowrdActive && (
            <ChangePasswordModal
              onComplete={logout}
              show={changePassowrdActive}
              setShow={setChangePassowrdActive}
            />
          )}
          {isSideMenuOpened && (
            <SideMenuBar
              onClose={() => setIsSideMenuOpened(false)}
              logout={logout}
              user={userResponse}
              changePassword={() => {
                setIsSideMenuOpened(false);
                setChangePassowrdActive(true);
              }}
              isApproved={isApproved}
              isASMUser={pathname === "/asm"}
            />
          )}

          {isLoading && (
            <div className="fixed z-50 flex justify-center w-full h-full max-w-3xl opacity-60 bg-gradient-to-r from-primary-100 to-primary-900">
              {/* <div className="fixed z-50 flex items-center justify-center w-full h-full max-w-3xl bg-opacity-40 bg-primary"> */}
              {/* <div className="block w-0 h-20 duration-700 ease-in bg-logo transition-width after:w-60" /> */}
              <LoadingSpinner />
            </div>
          )}
          <div className="relative h-screen py-20 content-container max-h-sceen">
            {!isLoading && children}
          </div>
          {isCartOpen && <Cart />}

          {/* Botom Navigation */}
          {!route.includes("products") && !route.includes("asm") && (
            <div className="fixed bottom-0 z-20 flex w-full max-w-3xl text-xs font-semibold text-gray-300 shadow-top justify-evenly p-2.5 bg-white">
              {BOTTOM_TABS.map(
                ({ label, imageUrl, imageAlt, path, selectedImageUrl }) => {
                  let isActive =
                    path === "/" ? isDashboardScreen : pathname.includes(path);
                  if (isFieldExecutive && path.includes("/payments")) return;
                  return (
                    <button
                      className={`flex flex-col items-center disabled:hidden ${
                        isActive ? "text-primary font-bold" : ""
                      }`}
                      key={imageAlt}
                      disabled={
                        !isApproved && path !== "/" && path !== "/profile"
                      }
                      onClick={() =>
                        push(
                          `${path}?userId=${userId}&userToken=${userToken}`,
                          path
                        )
                      }
                    >
                      <Image
                        src={isActive ? selectedImageUrl : imageUrl}
                        height={30}
                        width={30}
                        alt={imageAlt}
                      />
                      {label}
                    </button>
                  );
                }
              )}
            </div>
          )}
        </div>
      );
  }
};

export default PageLayout;
