import React from "react";

const Card = ({ variant, children, className, ...props }) => {
  switch (variant) {
    case "dark":
      return (
        <div
          className={`bg-[#fbfbfb] border rounded-xl text-center p-2.5 ${className}`}
          {...props}
        >
          {children}
        </div>
      );

    case "secondary":
      return (
        <div
          {...props}
          className={`bg-gradient-to-b w-full from-secondary-100 to-secondary-400 rounded-[20px] text-center p-2.5 ${className}`}
        >
          {children}
        </div>
      );
    default:
      return (
        <div
          {...props}
          className="rounded-[20px] w-full shadow-lg p-2.5 bg-gradient-to-r from-primary-100 to-primary-900"
        >
          {children}
        </div>
      );
  }
};

export default Card;
