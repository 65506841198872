import React from "react";

const Button = ({
  children,
  variant,
  disabled = false,
  className,
  ...props
}) => {
  if (variant === "sm") {
    return (
      <button
        className={`bg-gradient-to-b from-primary-300 to-primary text-white rounded-lg font-semibold px-1.5 disabled:opacity-50 ${className}`}
        {...props}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
  if (variant === "danger") {
    return (
      <button
        className={`bg-gradient-to-b text-white w-full py-2.5 rounded-2xl text-lg font-semibold mb-3 disabled:opacity-50 ${className}`}
        {...props}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
  return (
    <button
      className={`bg-gradient-to-b from-primary-300 to-primary text-white w-full py-2.5 rounded-2xl text-lg font-semibold mb-3 disabled:opacity-50 ${className}`}
      {...props}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
