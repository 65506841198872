import Image from "next/image";
import React, { useEffect, useMemo, useState } from "react";
import { ORDER_TYPES } from "../../../constants";

import useServerAPI from "../../../customHooks/useServerAPI";
import { isUndefined } from "../../../helpers/utils";
import { useStateContext } from "../../../state/global";
import { updateOrder } from "../../../state/global/actions";
import Button from "../../bits/Button";
import CarCard from "../../bits/CarCard";
import Dropdown from "../../bits/Dropdown";
import LoadingSpinner from "../../bits/LoadingSpinner";
import Modal from "../../bits/Modal";
import Pagination from "../../bits/Pagination";
import SearchInput from "../../bits/SearchInput";
import TextLink from "../../bits/TextLink";
import CartCarGroupView from "../CartCarGroupView";

const FUEL_TYPE = ["PETROL", "DIESEL", "CNG", "EV"];
const OEM_START_YEAR = 1995;
const VMOTIVE_START_YEAR = 1998;

const getYearsRange = (prev) => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: currentYear + 1 - prev }, (_, i) => prev + i);
};

const CarSearch = ({
  activeCar,
  setActiveCar,
  carSelectionActive,
  setCarSelectionActive,
  carGroupViewActive,
  setCarGroupViewActive,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedYear, setSelectedYear] = useState();
  const [selectedCar, setSelectedCar] = useState(activeCar);
  const [addNewCar, setAddNewCar] = useState(false);
  const [selectedFuelType, setSelectedFuelType] = useState();
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [{ cart, currentOrder }, dispatch] = useStateContext();

  const [carsResponse, carSearchInProgress, getCars] = useServerAPI("getCars");
  const [changeCarCheckRes, , changeCarCheck] = useServerAPI("changeCarCheck");
  const [changeCarConfirmRes, , changeCarConfirm] =
    useServerAPI("changeCarConfirm");
  const [getActiveOrderRes, , getActiveOrder] = useServerAPI("getActiveOrder");

  useEffect(() => {
    if (getActiveOrderRes) {
      dispatch(updateOrder(getActiveOrderRes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActiveOrderRes]);
  useEffect(() => {
    if (searchValue)
      getCars({
        variant_string: searchValue,
        year: selectedYear,
        fuel_type: selectedFuelType,
        start_index: (page - 1) * 10,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, selectedFuelType, selectedYear, page]);

  const years = getYearsRange(
    currentOrder?.order_type === ORDER_TYPES[0]
      ? VMOTIVE_START_YEAR
      : OEM_START_YEAR
  );
  const carList = carsResponse && carsResponse?.result;

  const carsInCart = useMemo(() => {
    return (
      Object.values(cart?.carModel || {})?.filter(
        (car) => activeCar?.car_variant_id != car.car_variant_id
      ) || []
    );
  }, [activeCar, cart?.carModel]);
  const isSingleCar = carsInCart.length === 1;
  const totalQuantity = carsInCart.reduce(
    (prev, { items }) => prev + items.length,
    0
  );
  useEffect(() => {
    if (carsInCart.length > 0) {
      setCarSelectionActive(false);
      setSelectedCar(carsInCart[0]);
      setActiveCar(carsInCart[0]);
    }
  }, []);

  useEffect(() => {
    if (changeCarCheckRes) {
      setShowModal(true);
    }
  }, [changeCarCheckRes]);

  useEffect(() => {
    if (changeCarConfirmRes) {
      setShowModal(false);
      getActiveOrder(ORDER_TYPES[0]);
    }
  }, [changeCarConfirmRes]);

  if (carGroupViewActive) {
    return (
      <CartCarGroupView
        carList={carsInCart}
        onClick={(car) => {
          setActiveCar(car);
          setSelectedCar(car);
          setCarGroupViewActive(false);
        }}
      />
    );
  }

  return (
    <>
      {changeCarCheckRes && showModal && (
        <Modal
          onCloseModal={() => setShowModal(false)}
          show={showModal}
          setShow={setShowModal}
        >
          <h1 className="mb-4 font-medium text-red-600">Alert</h1>
          <p className="text-base">
            {changeCarCheckRes.total_match_order_items} items match for the new
            car selected. The remaining{" "}
            {changeCarCheckRes.total_unmatch_order_items} items which do not
            match, will be removed from cart.
          </p>
          <div className="flex justify-between w-full">
            <button
              className="font-semibold w-36"
              onClick={() => {
                setCarSelectionActive(false);
                setSelectedCar(activeCar);
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <Button
              className="w-36"
              onClick={() => {
                changeCarConfirm({
                  oldCarId: activeCar.car_variant_id,
                  newCarId: selectedCar.car_variant_id,
                  orderId: currentOrder.id,
                });
                setCarSelectionActive(false);
                setActiveCar(selectedCar);
              }}
            >
              Continue
            </Button>
          </div>
        </Modal>
      )}
      {!carSelectionActive ? (
        <>
          {isSingleCar ? (
            <CarCard
              brand={carsInCart[0].brand}
              model={carsInCart[0].name}
              fuelType={carsInCart[0].fuel_type}
              variant={carsInCart[0].variant}
              year={carsInCart[0].start_year}
              imageUrl={carsInCart[0].image || carsInCart[0].image_url}
            >
              <TextLink
                className="flex justify-end"
                onClick={() => setCarGroupViewActive(true)}
              >
                {carsInCart[0]?.items.length} Parts
              </TextLink>
            </CarCard>
          ) : (
            <></>
          )}
          {carsInCart?.length > 1 && (
            <div className="flex items-center justify-between w-full p-4 bg-gray-100 border rounded-lg">
              <p className="text-lg font-semibold leading-tight">
                {carsInCart.length} cars
              </p>
              <p className="text-sm font-normal text-[#bdbdbd]">
                {totalQuantity} parts Added
              </p>
              <TextLink
                className="flex justify-end"
                onClick={() => setCarGroupViewActive(true)}
              >
                View Cars
              </TextLink>
            </div>
          )}
          <TextLink
            className="flex justify-end"
            onClick={() => {
              setAddNewCar(true);
              setCarSelectionActive(true);
            }}
          >
            + Add Car
          </TextLink>
          <CarCard
            brand={activeCar.brand}
            model={activeCar.name}
            imageUrl={activeCar.image || activeCar.image_url}
            fuelType={activeCar.fuel_type}
            variant={activeCar.variant}
            year={activeCar.start_year}
            hideBackground
          >
            <span
              className="cursor-pointer"
              onClick={() => {
                setCarSelectionActive(true);
                setSelectedCar(null);
              }}
            >
              <Image
                src="/assets/icons/edit.svg"
                height={22}
                width={22}
                alt="edit"
              />
            </span>
          </CarCard>
        </>
      ) : (
        <>
          {!addNewCar && activeCar && (
            <CarCard
              brand={activeCar.brand}
              model={activeCar.name}
              fuelType={activeCar.fuel_type}
              variant={activeCar.variant}
              year={activeCar.start_year}
              imageUrl={activeCar.image || activeCar.image_url}
              onSelect={() => {
                setCarSelectionActive(false);
                setSelectedCar(activeCar);
              }}
              hideBackground
            />
          )}
          <SearchInput
            placeholder="Search Cars by Name"
            value={searchValue}
            onChange={(ev) => {
              setPage(1);
              setSearchValue(ev.target.value);
            }}
          />
          <div className="flex items-center justify-end space-x-3">
            {searchValue && !isUndefined(carsResponse?.matches_count) && (
              <p className="mr-auto font-semibold">
                {carsResponse.matches_count} cars found for &quot;
                {searchValue}&quot;
              </p>
            )}
            <Dropdown
              options={years}
              intialValue={selectedYear}
              onChange={setSelectedYear}
              placeholder="Years"
            />
            <Dropdown
              options={FUEL_TYPE}
              intialValue={selectedFuelType}
              onChange={setSelectedFuelType}
              placeholder="Fuel Type"
            />
          </div>
          {carSearchInProgress ? (
            <LoadingSpinner />
          ) : carList?.length > 0 ? (
            <>
              <div className="space-y-2.5">
                {/* TODO: Update key from index to car variant ID */}
                {carList?.map((car, index) => (
                  <CarCard
                    key={index}
                    brand={car.brand}
                    model={car.name}
                    fuelType={car.fuel_type}
                    variant={car.variant}
                    year={car.start_year}
                    imageUrl={car.image || car.image_url}
                    {...car}
                    onSelect={() => {
                      if (!addNewCar && activeCar && currentOrder?.id) {
                        changeCarCheck({
                          oldCarId: activeCar.car_variant_id,
                          newCarId: car.car_variant_id,
                          orderId: currentOrder.id,
                        });
                      } else {
                        setCarSelectionActive(false);
                        setActiveCar(car);
                        setAddNewCar(false);
                      }
                      setSelectedCar(car);
                    }}
                  />
                ))}
                <Pagination
                  totalCount={carsResponse?.matches_count}
                  pageSize={10}
                  currentPage={page}
                  onPageChange={setPage}
                />
              </div>
            </>
          ) : (
            carList?.length === 0 && (
              <div className="py-24 text-center">
                <Image
                  src="/assets/images/noResults.png"
                  alt="No Results"
                  height={82}
                  width={160}
                />
                <h2 className="mt-4 font-medium">No results found</h2>
                <p className="text-[#bdbdbd]">
                  There are no results matching your search. Please check the
                  keyword or try another keyword for better results.
                </p>
              </div>
            )
          )}
        </>
      )}
    </>
  );
};

export default CarSearch;
