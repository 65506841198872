export const REGISTRATION_FORM = {
  basicDetails: [
    {
      required: true,
      name: "firmName",
      label: "Firm name",
      placeholder: "Firm name here",
    },
    {
      required: true,
      name: "ownerName",
      label: "Owner name",
      placeholder: "Owner name here",
    },
    {
      required: true,
      name: "businessType",
      label: "Business type",
      options: [
        { label: "Retailer", value: "Retailer" },
        { label: "Distributor", value: "Distributor" },
      ],
      inputType: "radio",
    },
    {
      required: true,
      name: "phone",
      label: "Phone number",
      placeholder: "Your mobile number here",
      type: "number",
      maxLength: 10,
      disabled: true,
    },
    {
      required: true,
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "Email here",
    },
  ],
  addressDetails: [
    {
      required: true,
      name: "billingAddressName",
      label: "Billing Address",
      placeholder: "Address Name",
    },
    {
      required: true,
      name: "billingAddressLine1",
      placeholder: "Address Line 1",
    },
    {
      name: "billingAddressLine2",
      placeholder: "Address Line 2",
    },
    {
      required: true,
      name: "billingPincode",
      placeholder: "Pincode",
      type: "number",
      maxLength: 6,
    },
    { required: true, name: "billingCity", placeholder: "City" },
    { required: true, name: "billingState", placeholder: "State" },
    {
      name: "sameAddress",
      label: "Shipping Address",
      placeholder: "Keep shipping address same as billing address",
      inputType: "checkbox",
      options: [
        {
          value: "sameAsBilling",
          label: "Keep shipping address same as billing address",
        },
      ],
    },
    {
      hideWhen: "sameAddress.sameAsBilling",
      required: true,
      name: "shippingAddressName",
      placeholder: "Address Name",
    },
    {
      hideWhen: "sameAddress.sameAsBilling",
      required: true,
      name: "shippingAddressLine1",
      placeholder: "Address Line 1",
    },
    {
      hideWhen: "sameAddress.sameAsBilling",
      name: "shippingAddressLine2",
      placeholder: "Address Line 2",
    },
    {
      hideWhen: "sameAddress.sameAsBilling",
      required: true,
      name: "shippingPincode",
      placeholder: "Pincode",
      type: "number",
      maxLength: 6,
    },
    {
      hideWhen: "sameAddress.sameAsBilling",
      required: true,
      name: "shippingCity",
      placeholder: "City",
    },
    {
      hideWhen: "sameAddress.sameAsBilling",
      required: true,
      name: "shippingState",
      placeholder: "State",
    },
  ],
  businessDetails: [
    {
      required: true,
      name: "gstNumber",
      label: "GSTIN number",
      placeholder: "GST number here",
    },
    {
      required: true,
      name: "gstCertificate",
      label: "GST Certificate",
      placeholder: "Upload GST certificate here (pdf/jpg)",
      inputType: "file",
      accept: "application/pdf,image/jpeg",
    },
    {
      required: true,
      name: "aadharNumber",
      label: "Aadhar Number",
      placeholder: "Aadhar number here",
      type: "number",
      maxLength: 12,
    },
    {
      required: true,
      name: "aadharCard",
      label: "Aadhar Upload",
      placeholder: "Upload Aadhar copy here (pdf/jpg)",
      inputType: "file",
      accept: "application/pdf,image/jpeg",
    },
    {
      required: true,
      name: "panNumber",
      label: "PAN Number",
      placeholder: "PAN number here",
    },
    {
      required: true,
      name: "panCard",
      label: "PAN Upload",
      placeholder: "Upload PAN copy here (pdf/jpg)",
      inputType: "file",
      accept: "application/pdf,image/jpeg",
    },
  ],
};

export const BASIC_DETAILS = "basicDetails";
export const BUSINESS_DETAILS = "businessDetails";
export const ADDRESS_DETAILS = "addressDetails";
export const STEPS = [
  { label: "1. Basic Details", value: BASIC_DETAILS },
  { label: "2. Business Details", value: BUSINESS_DETAILS },
  { label: "3. Address Details", value: ADDRESS_DETAILS },
];

export const ADD_ADDRESS_FIELDS = [
  {
    required: true,
    name: "alias",
    placeholder: "Address Name",
  },
  {
    required: true,
    name: "address1",
    placeholder: "Address Line 1",
  },
  {
    name: "address2",
    placeholder: "Address Line 2",
  },
  {
    required: true,
    name: "pin_code",
    placeholder: "Pincode",
    type: "number",
    maxLength: 6,
  },
  { required: true, name: "city", placeholder: "City" },
  { required: true, name: "state", placeholder: "State" },
];
