import React from "react";
import Button from "../../bits/Button";
import Modal from "../../bits/Modal";

const BookingSummaryModal = ({
  show,
  setShow,
  selectedCustomer,
  selectedDistributor,
  file,
  orderValue,
  disabled,
  placeOrder = () => {},
}) => {
  return (
    <Modal show={show} setShow={setShow} onCloseModal={() => {}}>
      <h1 className="text-2xl font-bold">Summary</h1>
      <div className="px-2.5 py-5 ">
        <h2>
          <span className="font-semibold">Order Value:</span> {orderValue}
        </h2>
        <h2>
          <span className="font-semibold">File:</span> {"" + file?.name}
        </h2>
        <h2>
          <span className="font-semibold">Customer:</span>{" "}
          {selectedCustomer?.name}
        </h2>
        <h2>
          <span className="font-semibold">Phone:</span>{" "}
          {selectedCustomer?.phone}
        </h2>
        <h2>
          <span className="font-semibold">Distributor:</span>{" "}
          {selectedDistributor?.name}
        </h2>
      </div>
      <Button
        className="w-40 py-2 rounded-2xl"
        variant="sm"
        disabled={disabled}
        onClick={placeOrder}
      >
        Comfirm Order
      </Button>
    </Modal>
  );
};

export default BookingSummaryModal;
