import Image from "next/image";
import { useEffect, useState } from "react";

import useServerAPI from "../../../customHooks/useServerAPI";
import LoadingSpinner from "../../bits/LoadingSpinner";
import ProductCard from "../../blocks/ProductCard";
import SearchInput from "../../bits/SearchInput";
import Pagination from "../../bits/Pagination";
import { isNull, isUndefined } from "../../../helpers/utils";

const ProductSearch = ({
  activeCar,
  activeCategory,
  activeCategoryFilter,
  placeholder = "Search",
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [productResponse, productSearchInProgress, getProducts] =
    useServerAPI("getProducts");

  useEffect(() => {
    if (searchValue || !isNull(activeCar) || !isNull(activeCategory)) {
      let params = {
        search: searchValue,
        start_index: (page - 1) * 10,
        page,
      };
      if (activeCar) {
        params = {
          ...params,
          car_variant_id: activeCar.car_variant_id,
        };
      } else if (activeCategory) {
        params = {
          ...params,
          family: activeCategory?.name,
          primary_filter: activeCategory?.subCategory,
          secondary_filter: activeCategoryFilter,
        };
      }
      getProducts(params);
    }
  }, [searchValue, activeCar, page, activeCategory, activeCategoryFilter]);

  const productList = productResponse?.result;

  const onSearchChange = (ev) => {
    setPage(1);
    setSearchValue(ev.target.value);
  };

  return (
    <>
      {isUndefined(activeCategory) && (
        <SearchInput
          value={searchValue}
          onChange={onSearchChange}
          placeholder={placeholder}
        />
      )}
      {!productSearchInProgress ? (
        (searchValue || !isUndefined(activeCategory)) &&
        productList?.length > 0 ? (
          <>
            <p className="font-semibold">
              {productResponse.matches_count ?? productResponse.count} Parts
              found
              {searchValue ? ` for "${searchValue}"` : ""}
            </p>
            <div className="space-y-2.5">
              {productList?.map((product) => (
                <ProductCard
                  key={product.sku_id}
                  part_name={product.part_description || product.description}
                  part_num={product.part_number}
                  amount={product.mrp}
                  discounted_price={product.discounted_price}
                  imageUrl={product.image || product.image_url}
                  manufacturer={
                    product.manufacturer?.name || product.manufacturer
                  }
                  lot_size={product.lot_size}
                  sku_id={product.sku_id}
                  part_id={product.part_id}
                  activeCar={activeCar}
                  activeCategory={activeCategory}
                  activeCategoryFilter={activeCategoryFilter}
                  extraDesc={["TRANSMISSION OIL","ENGINE OIL"].includes(activeCategory?.name) && product.secondary_filter}
                />
              ))}
            </div>
            <Pagination
              totalCount={
                productResponse.matches_count ?? productResponse.count
              }
              pageSize={10}
              currentPage={page}
              onPageChange={setPage}
            />
          </>
        ) : (
          productList?.length === 0 && (
            <div className="py-24 text-center">
              <Image
                src="/assets/images/noResults.png"
                alt="No Results"
                height={82}
                width={160}
              />
              <h2 className="mt-4 font-medium">No results found</h2>
              <p className="text-[#bdbdbd]">
                There are no results matching your search. Please check the
                keyword or try another keyword for better results.
              </p>
            </div>
          )
        )
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default ProductSearch;
