import React from "react";

const SearchInput = (props) => {
  return (
    <input
      {...props}
      className="w-full px-4 py-2 text-lg bg-no-repeat border-2 rounded-lg bg-search bg-center-right"
    />
  );
};

export default SearchInput;
