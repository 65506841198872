const queryStringBuilder = finalFilter => {
    let queryArray = []
    Object.keys(finalFilter).forEach((key, index) => {
      const keyValue = finalFilter[key]
      let query = ''
      let identifier = queryArray.length ? '&' : '?'
      if (Array.isArray(keyValue)) {
        keyValue.forEach((value, index) => {
          query += `${query ? '&' : ''}${key}=${value}`
        })
      } else if (keyValue) {
        query += `${key}=${keyValue}`
      }
      if (query) {
        queryArray.push(`${identifier}${query}`)
      }
    })
    return queryArray.join('')
  }
  
  export default queryStringBuilder
  
