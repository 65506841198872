const CheckboxOption = ({ name, value, label, ...props }) => {
    return (
      <div className="flex items-center mt-2">
        <input
          type="checkbox"
          id={value}
          value={value}
          name={name}
          className="inline-block w-4 h-4 mr-2 border-2 border-gray-500 rounded appearance-none cursor-pointer checked:bg-checked checked:border-none peer"
          {...props}
        />
        <label
          htmlFor={value}
          className="text-[#bdbdbd] peer-checked:text-primary"
        >
          {label}
        </label>
      </div>
    );
  };
  
  export default CheckboxOption;
  