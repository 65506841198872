import "../styles/globals.css";
import "tailwindcss/tailwind.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import PageLayout from "../components/HOC/PageLayout";
import { AuthProvider } from "../state/auth";
import { AUTH_INITIAL_STATE, authReducers } from "../state/auth/reducer";
import { StateProvider } from "../state/global";
import { globalReducer, INITIAL_STATE } from "../state/global/reducer";
import LayoutV2 from "../components/HOC/LayoutV2";

function MyApp({ Component, pageProps }) {
  return (
    <AuthProvider initialState={AUTH_INITIAL_STATE} reducer={authReducers}>
      <StateProvider initialState={INITIAL_STATE} reducer={globalReducer}>
        <LayoutV2>
          <PageLayout>
            <Component {...pageProps} />
          </PageLayout>
        </LayoutV2>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnHover={false}
        />
      </StateProvider>
    </AuthProvider>
  );
}

export default MyApp;
