import React from "react";

const Modal = ({
  children,
  onCloseModal = () => {},
  show,
  setShow,
  restrictOutsideClick = false,
  showCrossIcon = false,
}) => {
  const closeModal = () => {
    if (restrictOutsideClick) return;
    onCloseModal();
    setShow(false);
  };
  return (
    show && (
      <div
        className="absolute top-0 z-50 w-full h-full max-w-3xl backdrop-blur-[2px] flex items-center"
        onClick={closeModal}
      >
        <div
          className="w-full mx-4 bg-white rounded shadow-2xl h-fit"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="relative flex flex-col items-center justify-center px-5 py-4">
            {showCrossIcon && (
              <button
                className="absolute text-lg top-2 right-2"
                onClick={() =>
                  restrictOutsideClick ? onCloseModal() : closeModal()
                }
              >
                X
              </button>
            )}
            {children}
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
