import { httpRequest } from "../helpers/http";
import {
  getCookieItem,
  getIsFieldExecutive,
  isUndefined,
} from "../helpers/utils";

const baseURL = `${process.env.NEXT_PUBLIC_URL}/business`;
const commonURL = `${process.env.NEXT_PUBLIC_URL}/common`;
const dmsURL = `${process.env.NEXT_PUBLIC_URL}/spare-parts`;
const opsURL = `${process.env.NEXT_PUBLIC_URL}/ops`;

const URLS = {
  sendOTP: `${baseURL}/user/send-signup-code/`,
  validateOTP: `${baseURL}/user/validate-auth-code/`,
  loginWithPassword: `${opsURL}/user/login-with-password/`,
  changePassword: `${opsURL}/user/change-password/`,
  user: `${baseURL}/user/`,
  address: `${baseURL}/address/`,
  order: `${baseURL}/order/`,
  placeOrderByExcel: `${baseURL}/order/place-order-by-excel/`,
  placeOrderByImage: `${baseURL}/order/place-order-by-image/`,
  paymentSummary: `${baseURL}/invoice/pending-payment-summary/`,
  getActiveOrder: `${baseURL}/order/get-active-order/`,
  getDistributorSummary: `${baseURL}/order/seller-summary/`,
  getUploadUrl: `${commonURL}/storage/get-upload-url/`,
  getDownloadUrl: `${commonURL}/storage/get-download-url/`,
  ordersShortSummary: `${baseURL}/order/short-summary-active-orders/`,
  ordersSummary: `${baseURL}/order/summary/`,
  orderItem: `${baseURL}/order-item/`,
  activeOrders: `${baseURL}/order-item/active-orders-items-by-status/`,
  invoice: `${baseURL}/invoice/`,
  parts: `${dmsURL}/part/`,
  partSearch: `${dmsURL}/v-motive/`,
  cars: `${dmsURL}/car-variant/`,
  family: `${dmsURL}/family/`,
  familyFilters: `${dmsURL}/family-filters/`,
  customer: `${opsURL}/user/`,
};

// Authentiaction APIs
export const sendOTP = (phoneNumber) => {
  return httpRequest({
    url: URLS.sendOTP,
    method: "post",
    data: { phone: phoneNumber },
  });
};

export const verifyOTP = (data) => {
  return httpRequest({
    url: URLS.validateOTP,
    method: "post",
    data,
  });
};

export const loginWithPassword = (data) => {
  return httpRequest({
    method: "post",
    url: URLS.loginWithPassword,
    data,
  });
};

export const changePassword = (data) => {
  return httpRequest({
    method: "patch",
    url: URLS.changePassword,
    data,
    withAuthToken: true,
  });
};

// USERS API
export const getUser = (data, options) => {
  return httpRequest({
    url: `${URLS.user}${data.userId}/`,
    method: "get",
    data,
    withAuthToken: isUndefined(options),
    options,
  });
};

export const getUserSummary = (data, options) => {
  return httpRequest({
    url: `${URLS.user}${data.userId}/summary/`,
    method: "get",
    data,
    withAuthToken: isUndefined(options),
    options,
  });
};

export const updateUser = (data) => {
  return httpRequest({
    url: `${URLS.user}${data.userId}/`,
    method: "patch",
    data,
    withAuthToken: true,
  });
};

export const updateBusinessDetails = (data) => {
  return httpRequest({
    url: `${URLS.user}${data.userId}/update-business/`,
    method: "patch",
    data,
    withAuthToken: true,
  });
};

export const addUserAddress = (data) => {
  return httpRequest({
    url: URLS.address,
    method: "post",
    data,
    withAuthToken: true,
  });
};

export const getUserAddresses = (data, options) => {
  return httpRequest({
    url: URLS.address,
    method: "get",
    data,
    withAuthToken: isUndefined(options),
    options,
  });
};

// DMS API
export const getProducts = (data) => {
  return httpRequest({
    url: data.family ? URLS.partSearch : URLS.parts,
    method: "get",
    queryParams: data,
    withAuthToken: true,
  });
};

// Orders API

export const addOrderItem = (data) => {
  return httpRequest({
    url: URLS.orderItem,
    method: "post",
    data,
    withAuthToken: true,
  });
};

export const createOrder = (data) => {
  return httpRequest({
    url: URLS.order,
    method: "post",
    data,
    withAuthToken: true,
  });
};

export const placeOrderByExcel = (data) => {
  return httpRequest({
    url: data.distributor_id ? URLS.placeOrderByImage : URLS.placeOrderByExcel,
    method: "post",
    data,
    withAuthToken: true,
  });
};

export const placeOrder = (data) => {
  return httpRequest({
    url: `${URLS.order}${data.id}/place-order/`,
    method: "patch",
    data: { shipping_address_id: data.shipping_address_id },
    withAuthToken: true,
  });
};

export const updateOrderItem = (data) => {
  return httpRequest({
    url: `${URLS.orderItem}${data.orderItemId}/`,
    method: "patch",
    data,
    withAuthToken: true,
  });
};

export const deleteOrderItem = (data) => {
  return httpRequest({
    url: `${URLS.orderItem}${data.orderItemId}/`,
    method: "delete",
    withAuthToken: true,
  });
};

// Payment Summary
export const getPaymentSummary = (options) => {
  return httpRequest({
    url: URLS.paymentSummary,
    method: "get",
    withAuthToken: isUndefined(options),
    options,
  });
};

export const getActiveOrder = (orderType, options) => {
  const selectedCustomer = JSON.parse(getCookieItem("selectedCustomer"));
  const isFieldExecutive = getIsFieldExecutive();
  const queryParams = {
    order_type: orderType,
  };
  if (isFieldExecutive && selectedCustomer?.id) {
    queryParams.user_id = selectedCustomer?.id;
  } else if (isFieldExecutive && !selectedCustomer?.id) {
    return;
  }
  return httpRequest({
    url: URLS.getActiveOrder,
    method: "get",
    queryParams,
    withAuthToken: isUndefined(options),
    options,
  });
};

// Car Search API
export const getCars = (data) => {
  return httpRequest({
    url: URLS.cars,
    method: "get",
    queryParams: data,
    withAuthToken: true,
  });
};
export const changeCarCheck = (data) => {
  return httpRequest({
    url: `${URLS.order}${data.orderId}/change-car/`,
    method: "patch",
    data: {
      original_car_variant_id: data.oldCarId,
      new_car_variant_id: data.newCarId,
      action: "validate",
    },
    withAuthToken: true,
  });
};
export const changeCarConfirm = (data) => {
  return httpRequest({
    url: `${URLS.order}${data.orderId}/change-car/`,
    method: "patch",
    data: {
      original_car_variant_id: data.oldCarId,
      new_car_variant_id: data.newCarId,
      action: "confirm",
    },
    withAuthToken: true,
  });
};

// Storage API's
export const getUploadUrl = (fileType) => {
  return httpRequest({
    url: URLS.getUploadUrl,
    method: "get",
    queryParams: { file_type: fileType },
    withAuthToken: true,
  });
};

export const getDownloadUrl = (filename) => {
  return httpRequest({
    url: URLS.getDownloadUrl,
    method: "get",
    queryParams: { file_path: filename },
    withAuthToken: true,
  });
};

// Order Sort Summary
export const getOrdersShortSummary = (options) => {
  return httpRequest({
    url: URLS.ordersShortSummary,
    method: "get",
    withAuthToken: isUndefined(options),
    options,
  });
};

export const getActiveOrdersSummary = (
  { page = 1, category = null },
  options
) => {
  return httpRequest({
    url: URLS.ordersSummary,
    method: "get",
    queryParams: { summary_type: "active", page, category },
    withAuthToken: isUndefined(options),
    options,
  });
};
export const getOrdersHistorySummary = (
  { page = 1, category = null },
  options
) => {
  return httpRequest({
    url: URLS.ordersSummary,
    method: "get",
    queryParams: { summary_type: "history", page, category },
    withAuthToken: isUndefined(options),
    options,
  });
};

export const getActiveOrderItems = (queryParams, options) => {
  return httpRequest({
    url: URLS.activeOrders,
    method: "get",
    queryParams,
    withAuthToken: isUndefined(options),
    options,
  });
};
export const getOrderDetails = (id, options) => {
  return httpRequest({
    url: `${URLS.order}${id}/order-tracking/`,
    method: "get",
    withAuthToken: isUndefined(options),
    options,
  });
};

export const getInTransitInvoices = (options) => {
  return httpRequest({
    url: URLS.invoice,
    method: "get",
    queryParams: { invoice_state: "shipped" },
    withAuthToken: isUndefined(options),
    options,
  });
};

export const getInvoicesDetails = (id, options) => {
  return httpRequest({
    url: `${URLS.invoice}${id}/invoice-with-items/`,
    method: "get",
    withAuthToken: isUndefined(options),
    options,
  });
};

export const getCustomersByAsm = (data) => {
  return httpRequest({
    url: URLS.customer,
    method: "get",
    queryParams: data,
    withAuthToken: true,
  });
};

export const loginAsCustomers = (id) => {
  return httpRequest({
    url: `${URLS.customer}${id}/login-as-customer/`,
    method: "post",
    withAuthToken: true,
  });
};

export const getPartFamilies = () => {
  return httpRequest({
    url: URLS.family,
    method: "get",
    queryParams: { manufacturer: "v-motive" },
    withAuthToken: true,
  });
};

export const getPartFamiliesFilters = () => {
  return httpRequest({
    url: URLS.familyFilters,
    method: "get",
    queryParams: { manufacturer: "v-motive" },
    withAuthToken: true,
  });
};

export const createSecondarySalesCustomer = (data) => {
  return httpRequest({
    url: `${URLS.user}secondary-sales-customer/`,
    method: "post",
    data,
    withAuthToken: true,
  });
};
export const getSecondarySalesCustomers = () => {
  return httpRequest({
    url: `${URLS.user}secondary-sales-customers/`,
    method: "get",
    withAuthToken: true,
  });
};

export const getDistributors = () => {
  return httpRequest({
    url: `${URLS.user}distributors/`,
    method: "get",
    withAuthToken: true,
  });
};
export const getSellerSummary = (month) => {
  return httpRequest({
    url: `${URLS.user}secondary-sales-summary/`,
    method: "get",
    queryParams: { month },
    withAuthToken: true,
  });
};

export const getDistributorSummary = () => {
  return httpRequest({
    url: URLS.getDistributorSummary,
    method: "get",
    withAuthToken: true,
  });
};

export const cancelOrder = (id) => {
  return httpRequest({
    url: `${URLS.order}${id}/cancel-order/`,
    method: "patch",
    withAuthToken: true,
  });
};
export const completeOrder = (id) => {
  return httpRequest({
    url: `${URLS.order}${id}/complete-order/`,
    method: "patch",
    withAuthToken: true,
  });
};
