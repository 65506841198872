import React from "react";
import RadioOption from "../../bits/RadioOption";

const RadioInputGroup = ({
  options = [],
  name = "",
  handleChange,
  label,
  value,
  ...inputProps
}) => {
  return (
    <div className="my-3">
      <h3 className="font-semibold">{label}</h3>
      <div className="flex px-2 space-x-5">
        {options.map((option) => (
          <RadioOption
            value={option.value}
            label={option.label}
            key={option.value}
            name={name}
            onChange={handleChange}
            checked={value === option.value}
            {...inputProps}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioInputGroup;
