import React from "react";

const Tabs = ({ list = [], selectedTab, setSelectedTab }) => {
  return (
    <div className="flex justify-around mb-3 font-medium text-center text-gray-300 border-b-2">
      {list.map((section) => (
        <span
          key={section}
          onClick={() => setSelectedTab(section)}
          className={
            section === selectedTab
              ? "w-full text-[#f27a21] border-b-2 border-[#f27a21] -mb-0.5"
              : "w-full cursor-pointer"
          }
        >
          {section}
        </span>
      ))}
    </div>
  );
};

export default Tabs;
