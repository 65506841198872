import { RESET } from "../global/actions";

export const INITIALIZE = "INITIALIZE";
export const UPDATE_USER_SUMMARY = "UPDATE_USER_SUMMARY";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_ID = "UPDATE_USER_ID";
export const UPDATE_ORDER = "UPDATE_ORDER";

export const AUTH_INITIAL_STATE = {
  user: {},
  userSummary: {},
  orderSummary: {},
  userId: null,
  userToken: null,
  initialized: false,
};
export const authReducers = (prevState, action) => {
  switch (action.type) {
    case UPDATE_USER_SUMMARY:
      return { ...prevState, userSummary: action.payload };
    case UPDATE_USER:
      return { ...prevState, user: action.payload };
    case UPDATE_ORDER:
      return { ...prevState, orderSummary: action.payload };
    case UPDATE_USER_ID:
      return {
        ...AUTH_INITIAL_STATE,
        userId: action.payload,
        initialized: true,
      };
    case INITIALIZE:
      return { ...prevState, ...action.payload, initialized: true };
    case RESET:
      return { ...AUTH_INITIAL_STATE, initialized: true };
    default:
      return prevState;
  }
};
