import Image from "next/image";
import React, { useEffect, useMemo, useState } from "react";
import useServerAPI from "../../../customHooks/useServerAPI";
import { isNull } from "../../../helpers/utils";
import Card from "../../bits/Card";
import SearchInput from "../../bits/SearchInput";

const CategorySearch = ({
  selected,
  onSelect,
  setActiveCategoryFilter,
  activeCategoryFilter,
}) => {
  const [searchValue, setSearchValue] = useState();
  const [filter, setFilter] = useState({});
  const [familiesFilters, , getPartFamiliesFilters] = useServerAPI(
    "getPartFamiliesFilters"
  );
  const [partFamilies, , getPartFamilies] = useServerAPI("getPartFamilies");

  const familiesList = useMemo(() => {
    const list = partFamilies ? partFamilies?.result : [];
    return list.filter(
      ({ name }) =>
        !searchValue || name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [partFamilies, searchValue]);

  const partFamiliesList = useMemo(() => {
    const list = familiesFilters ? familiesFilters?.result : [];
    return list.filter(
      ({ name }) =>
        !searchValue || name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [familiesFilters, searchValue]);

  useEffect(() => {
    getPartFamiliesFilters();
    getPartFamilies();
  }, []);

  return !isNull(selected) ? (
    <>
      <h2 className="flex text-lg font-semibold">
        <span
          className="mr-2 cursor-pointer"
          onClick={() => {
            onSelect(null);
            setActiveCategoryFilter(null);
          }}
        >
          <Image
            src="/assets/icons/leftArrowBlack.svg"
            height={25}
            width={25}
            alt="leftArrowBlack"
          />
        </span>{" "}
        {selected.subCategory || selected.name}
      </h2>
      <div className="flex space-x-3 overflow-scroll ">
        {selected.filters.map((item) => (
          <Card
            variant="dark"
            className={`flex justify-between cursor-pointer ${
              item === activeCategoryFilter ? "border-primary" : ""
            }`}
            key={item.replace(" ", "_")}
            onClick={() => {
              activeCategoryFilter === item
                ? setActiveCategoryFilter(null)
                : setActiveCategoryFilter(item);
            }}
          >
            <h1 className="text-lg font-semibold">{item}</h1>
          </Card>
        ))}
      </div>
    </>
  ) : filter?.category ? (
    <>
      <h2 className="flex text-lg font-semibold">
        <span
          className="mr-2 cursor-pointer"
          onClick={() => setFilter({}) && setActiveCategoryFilter(null)}
        >
          <Image
            src="/assets/icons/leftArrowBlack.svg"
            height={25}
            width={25}
            alt="leftArrowBlack"
          />
        </span>{" "}
        {filter.category.name}
      </h2>
      <div className="space-y-3 overflow-scroll">
        {filter.category.filter_options.map((item) => (
          <Card
            variant="dark"
            className="flex justify-between cursor-pointer"
            key={item.primary_filter?.replace(" ", "_")}
            onClick={() => {
              onSelect({
                name: filter.category.name,
                subCategory: item.primary_filter,
                filters: item.secondary_filter,
              });
            }}
          >
            <h1 className="text-lg font-semibold">{item.primary_filter}</h1>
            <Image
              src="/assets/icons/rightArrowBlue.svg"
              height={20}
              width={20}
              alt="go"
            />{" "}
          </Card>
        ))}
      </div>
    </>
  ) : (
    <>
      <SearchInput
        value={searchValue}
        onChange={(ev) => setSearchValue(ev.target.value)}
        placeholder="Search Category"
      />
      <div className="grid grid-cols-3 p-5 sm:grid-cols-4 gap-x-3 gap-y-5">
        {partFamiliesList.map((category, i) => (
          <div
            className="border text-sm rounded-3xl p-3.5 flex flex-col justify-between items-center cursor-pointer"
            onClick={() => setFilter({ category })}
            key={i}
          >
            <span className="relative">
              <Image
                src={
                  familiesList?.[i]?.image_url ||
                  "/assets/images/dummyCategory.png"
                }
                height={60}
                width={60}
                alt={category.name}
              />
            </span>
            {category.name}
          </div>
        ))}
      </div>
    </>
  );
};

export default CategorySearch;
