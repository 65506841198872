/**
 * Gets the value at `path` of `object`. If the resolved value is
 * `undefined`, the `defaultValue` is returned in its place.
 *
 * @param {Object} object The object to query.
 * @param {Array|string} path The path of the property to get.
 * @param {*} [defaultValue] The value returned for `undefined` resolved values.
 * @returns {*} Returns the resolved value.
 * 
 * const object = { 'a': { 'b': { 'c': 3 } } }
 *
 *
 * getValueByPath(object, ['a', 'b', 'c'])
 * // => 3
 *
 * getValueByPath(object, 'a.b.c', 'default')
 * // => 3
 * 
 * getValueByPath(object, 'a.b.d', 'default')
 * // => 'default'
 */

export const getValueByPath = (obj, path, defaultValue = undefined) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};
