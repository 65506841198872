import React from "react";

import Button from "../../bits/Button";

const QuantityChanger = ({ quantity, setQuantity, lotSize }) => {
  return (
    <div className="flex flex-col items-center my-1 space-x-2 sm:flex-row">
      <div className="flex my-1 space-x-2">
        <p className="text-[#bdbdbd]">Qty</p>
        <Button
          variant="sm"
          onClick={() => quantity > 1 && setQuantity(quantity - 1)}
          className="h-6"
        >
          -
        </Button>
        <input
          type="number"
          className="w-12 h-6 text-center bg-transparent border-b-2 border-gray-400"
          min={0}
          value={quantity}
          onChange={(ev) => setQuantity(ev.target.value)}
        />
        <Button
          className="h-6"
          variant="sm"
          onClick={() => setQuantity(quantity + 1)}
        >
          +
        </Button>
      </div>
      {lotSize > 1 && <p>X Pack of {lotSize}</p>}
    </div>
  );
};

export default QuantityChanger;
