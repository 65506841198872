import React from "react";
import { usePagination, DOTS } from "./usePagination";

const Pagination = ({
  totalCount = 0,
  pageSize = 1,
  currentPage = 1,
  siblingCount = 1,
  onPageChange,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  const lastPage = paginationRange[paginationRange.length - 1];
  const firstPage = paginationRange[0];

  return (
    <div className="flex justify-center w-full py-2">
      <button
        className="h-10 px-5 transition-colors duration-150 bg-white rounded-l-lg text-primary focus:shadow-outline hover:bg-primary-100 disabled:opacity-0"
        onClick={onPrevious}
        disabled={firstPage === currentPage}
      >
        Prev
      </button>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <button
              className="w-10 h-10 transition-colors duration-150 bg-white rounded-full text-primary focus:shadow-outline hover:bg-primary-100"
              disabled
              key={pageNumber + index}
            >
              {pageNumber}
            </button>
          );
        }

        return (
          <button
            className="w-10 h-10 transition-colors duration-150 bg-white rounded-full disabled:text-white text-primary disabled:bg-primary focus:shadow-outline hover:bg-primary-100"
            key={pageNumber}
            disabled={pageNumber === currentPage}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        );
      })}
      <button
        className="h-10 px-5 transition-colors duration-150 bg-white rounded-r-lg text-primary focus:shadow-outline hover:bg-primary-100 disabled:opacity-0"
        onClick={onNext}
        disabled={lastPage === currentPage}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
