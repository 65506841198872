import axios from "axios";
import Cookies from "js-cookie";
import { TRACKING_STATUSES } from "../components/bits/TrackingProgressBar";
import { getDownloadUrl, getUploadUrl } from "../services/masterService";

// Basic Utility methods

export const isNull = (value) => {
  return value === null;
};

export const isUndefined = (value) => {
  return typeof value === "undefined";
};

// LOCAL STORAGE Methods

export const getCookieItem = (key = "") => {
  const value = Cookies.get(key);
  if (typeof value === "undefined") {
    return null;
  }
  return value;
};

export const setCookieItem = (key = "", value) => {
  Cookies.set(key, value);
};

export const clearCookieItem = (key = "") => {
  Cookies.remove(key);
};

export const clearCookieItems = () =>
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    Cookies.remove(cookieName);
  });

export const getAuthToken = () => {
  const token = getCookieItem("userToken");
  if (typeof token === "undefined") {
    return null;
  }
  return token;
};
export const getIsFieldExecutive = () => {
  const isFieldExecutive = getCookieItem("isFieldExecutive");
  if (typeof isFieldExecutive === "undefined") {
    return null;
  }
  return isFieldExecutive === "true";
};

export const setAuthToken = (value) => setCookieItem("userToken", value);

export const resetAuthToken = () => clearCookieItem("userToken");

export const getAddressString = ({
  area = "",
  city = "",
  state = "",
  pin_code = "",
}) => {
  let addressStr = "";
  if (area) {
    addressStr += `${area},`;
  }
  if (city) {
    addressStr += ` ${city},`;
  }
  if (state) {
    addressStr += ` ${state},`;
  }
  if (city) {
    addressStr += ` ${city}`;
  }
  if (pin_code) {
    addressStr += ` - ${pin_code}`;
  }
  return addressStr;
};
// Special Methods
export const getInitials = (name) =>
  name &&
  name
    .match(/(^\S\S?|\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();

export const priceFormatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const getFileNameFromURL = (str) => {
  const regex = /[?]file_path=([^&#]*)/g;
  const match = regex.exec(str);
  return match[1];
};

export const convertToDate = (date) => {
  return (
    date &&
    new Date(date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  );
};

export const uploadFileToGCS = async (file) => {
  if (isUndefined(file) || isNull(file)) {
    return null;
  }
  if (isUndefined(file.type) && !isUndefined(file.name)) {
    return file.name;
  }
  const ext = file.name.split(".").pop();
  const signedUrlResponse = await getUploadUrl(ext.toLowerCase());
  if (signedUrlResponse.server_file_name) {
    const res = await axios.put(signedUrlResponse.fixcraft_path, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    if (res.status === 200) return signedUrlResponse.server_file_name;
    throw new Error("File Not Uploaded");
  }
};

export const downloadFileFromGCS = async (fileName = "") => {
  let fileUrl = await getDownloadUrl(fileName);
  const response = await fetch(fileUrl);
  const blob = await response.blob();
  const elem = window.document.createElement("a");
  elem.href = window.URL.createObjectURL(blob);
  elem.download = fileName;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};
export const openFileFromGCS = async (fileName = "") => {
  let fileUrl = await getDownloadUrl(fileName);
  const elem = window.document.createElement("a");
  elem.href = fileUrl;
  elem.target = "_blank";
  elem.download = fileName;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

export const isInvoiceNotDelivered = (status) =>
  status === TRACKING_STATUSES[0] || status === TRACKING_STATUSES[1];

export const initalizeStateFromCookies = async () => {
  const state = {
    initialized: true,
  };
  const cookies = Cookies.get();
  if (cookies.userToken && cookies.userId) {
    state.userId = cookies.userId;
    state.userToken = cookies.userToken;
  }
  return state;
};

export const getDifferenceInDays = (date) =>
  parseInt(
    (new Date().getTime() - new Date(date).getTime()) / (1000 * 3600 * 24)
  );
