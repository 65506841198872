import React, { useCallback, useEffect, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { toast } from "react-toastify";

import { ORDER_TYPES } from "../../../constants";
import useServerAPI from "../../../customHooks/useServerAPI";
import {
  clearCookieItem,
  getAddressString,
  getCookieItem,
  getIsFieldExecutive,
  isNull,
  uploadFileToGCS,
} from "../../../helpers/utils";
import Button from "../../bits/Button";
import FileInputGroup from "../../blocks/FileInputGroup";
import Modal from "../../bits/Modal";
import InputGroup from "../../blocks/InputGroup";
import BookingSummaryModal from "../../blocks/BookingSummaryModal";

const SAMPLE_FILE = {
  OEM: { name: "sample_oem", href: "/assets/sample_oem.xlsx" },
  "V-Motive": { name: "sample_vmotive", href: "/assets/sample_vmotive.xlsx" },
};

const BulkOrder = ({ initalCategory }) => {
  const { push } = useRouter();
  const [selectedCategory, setSelectedCategory] = useState(initalCategory);
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [orderValue, setOrderValue] = useState("");
  const [orderResponse, isLoading, placeOrderByExcel] =
    useServerAPI("placeOrderByExcel");
  const selectedCustomer = JSON.parse(
    getCookieItem("selectedCustomer") || "{}"
  );
  const selectedDistributor = JSON.parse(
    getCookieItem("selectedDistributor") || "{}"
  );
  const isFieldExecutive = getIsFieldExecutive();
  const onFileUpload = async (file) => {
    if (file) {
      setFile(file);
    } else {
      setFile(null);
    }
  };

  const placeOrder = async () => {
    try {
      if (isFieldExecutive) {
        !showSummaryModal
          ? setShowSummaryModal(true)
          : placeOrderByExcel({
              order_type: selectedCategory,
              server_file_name: await uploadFileToGCS(file),
              user_file_name: file.name,
              order_value: orderValue,
              user_id: selectedCustomer.id,
              distributor_id: selectedDistributor.id,
            });
      } else {
        placeOrderByExcel({
          order_type: selectedCategory,
          server_file_name: await uploadFileToGCS(file),
          user_file_name: file.name,
        });
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const closeModal = useCallback(() => {
    setShowModal(false);
    const userId = getCookieItem("userId");
    const userToken = getCookieItem("userToken");
    push(`/?userId=${userId}&userToken=${userToken}`, "/");
  }, [push]);

  useEffect(() => {
    if (orderResponse && !orderResponse?.message) {
      toast.success("Order Placed");
      clearCookieItem("selectedCustomer");
      clearCookieItem("selectedDistributor");
      setShowModal(true);
      setTimeout(closeModal, 3000);
    } else if (orderResponse?.message) {
      toast.error(orderResponse?.message);
    }
    return () => {
      setShowModal(false);
      setShowSummaryModal(false);
    };
  }, [closeModal, orderResponse]);

  return (
    <>
      <div className="flex flex-col justify-between h-full px-5 my-4">
        <div className="flex justify-around my-4">
          {ORDER_TYPES.slice(0, 2).map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              disabled={selectedCategory === category}
              className="px-4 py-2.5 text-lg border-2 disabled:font-semibold disabled:border-primary text-[#bdbdbd] disabled:text-primary rounded-xl disabled:"
            >
              {category} Parts
            </button>
          ))}
        </div>

        {!!selectedCustomer?.name && (
          <div>
            <h2 className="font-semibold">Customer Details:</h2>
            <div className="p-2.5 border rounded-lg">
              <h2>
                <span className="font-semibold">Name:</span>{" "}
                {selectedCustomer?.name}
              </h2>
              <h2>
                <span className="font-semibold">Phone:</span>{" "}
                {selectedCustomer?.phone}
              </h2>
              <p>
                <span className="font-semibold">Address:</span>{" "}
                {getAddressString(
                  selectedCustomer?.address[0] ?? selectedCustomer?.address
                )}
              </p>
            </div>
            <InputGroup
              label="Order Value:"
              value={orderValue}
              placeholder="Order value"
              handleChange={(ev) => setOrderValue(ev.target.value)}
            />
          </div>
        )}

        {!isFieldExecutive && isNull(file) && (
          <div className="w-2/3 mx-auto">
            <a
              href={SAMPLE_FILE[selectedCategory].href}
              download={SAMPLE_FILE[selectedCategory].name}
            >
              <Button>
                <Image
                  src="/assets/images/downloadWhite.png"
                  height={20}
                  width={20}
                  alt="download"
                />
                <span className="ml-4">Download sample file</span>
              </Button>
            </a>
          </div>
        )}
        {isFieldExecutive ? (
          <FileInputGroup
            placeholder="Click photo and upload"
            value={file}
            accept="image/png, image/gif, image/jpeg"
            handleFileChange={(value) => onFileUpload(value)}
          />
        ) : (
          <FileInputGroup
            placeholder="Upload Excel in given format"
            value={file}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            handleFileChange={(value) => onFileUpload(value)}
          />
        )}
        {!isNull(file) ? (
          <Button
            onClick={placeOrder}
            disabled={isLoading || (isFieldExecutive && !orderValue)}
          >
            Place Order
          </Button>
        ) : (
          <span />
        )}
      </div>
      {orderResponse && (
        <Modal
          show={showModal}
          setShow={setShowModal}
          onCloseModal={closeModal}
        >
          <h1 className="mb-4 text-xl font-semibold text-primary">Thank you</h1>
          <p className="mb-4 text-base">
            Your Order request has been raised. You will get notified once
            it&apos;s approved.
          </p>
          <div className="!w-36">
            <Button onClick={() => push("/")}>OK</Button>
          </div>
        </Modal>
      )}
      {showSummaryModal && (
        <BookingSummaryModal
          show={showSummaryModal}
          setShow={setShowSummaryModal}
          selectedCustomer={selectedCustomer}
          selectedDistributor={selectedDistributor}
          selectedCategory={selectedCategory}
          orderValue={orderValue}
          file={file}
          disabled={isLoading}
          placeOrder={placeOrder}
        />
      )}
    </>
  );
};

export default BulkOrder;
