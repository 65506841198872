import React, { useEffect, useState } from "react";
import Link from "next/link";

import Button from "../components/bits/Button";
import Card from "../components/bits/Card";
import TextLink from "../components/bits/TextLink";
import {
  REGISTRATION_PENDING_STATUS,
  VERIFICATION_PENDING_STATUS,
  VERIFICATION_PENDING_STATUS_DISPLAY,
} from "../constants";
import { getInitials, isUndefined } from "../helpers/utils";
import {
  getUser,
  getUserAddresses,
  getUserSummary,
} from "../services/masterService";
import useServerAPI from "../customHooks/useServerAPI";
import LoadingSpinner from "../components/bits/LoadingSpinner";
import { useRouter } from "next/router";

const SECTIONS = ["Basic Details", "Business Details"];
export const BILLING = "billing";

const Profile = (props) => {
  const router = useRouter();
  const [userSummary, setUserSummary] = useState(props.userSummary);
  const [user, setUser] = useState(props.user);
  // eslint-disable-next-line no-unused-vars
  const [userAddresses, setUserAddresses] = useState(props.userAddresses || []);

  const billingAddress = userAddresses.find(
    (address) => address.address_type === BILLING
  );
  const shippingAddresses = userAddresses.filter(
    (address) => address.address_type !== BILLING
  );

  const [userSummaryRes, , getUserSummaryAPI] = useServerAPI("getUserSummary");
  const [userRes, , getUserAPI] = useServerAPI("getUser");
  const [userAddressRes, , getUserAddressesAPI] =
    useServerAPI("getUserAddresses");

  useEffect(() => {
    getUserSummaryAPI();
    getUserAPI();
  }, [router.pathname]);

  useEffect(() => {
    // TODO:- Remove when cache proper fix found
    if (!isUndefined(userSummaryRes?.id)) {
      setUserSummary(userSummaryRes);
    }
  }, [userSummaryRes]);

  useEffect(() => {
    if (!isUndefined(userRes?.id)) {
      setUser(userRes);
      getUserAddressesAPI(userRes.id);
    }
  }, [userRes]);

  useEffect(() => {
    if (!isUndefined(userAddressRes)) {
      setUserAddresses(userAddressRes);
    }
  }, [userAddressRes]);

  // USE STATE Hooks
  const [selectedSection, setSelectedSection] = useState(SECTIONS[0]);

  // TODO:- Remove when cache proper fix found
  if (isUndefined(userSummaryRes) && isUndefined(userRes))
    return (
      <div className="fixed z-50 flex justify-center w-full h-full max-w-3xl opacity-60 bg-gradient-to-r from-primary-100 to-primary-900">
        {/* <div className="fixed z-50 flex items-center justify-center w-full h-full max-w-3xl bg-opacity-40 bg-primary"> */}
        {/* <div className="block w-0 h-20 duration-700 ease-in bg-logo transition-width after:w-60" /> */}
        <LoadingSpinner />
      </div>
    );

  return (
    <div className="flex flex-col h-full space-y-5">
      <Card>
        <div className="flex">
          <span className="p-2 mr-3.5 text-xl font-semibold text-center border-2 border-black rounded-full w-12 h-12">
            {getInitials(user.business?.name || user.name)}
          </span>
          <p className="text-lg font-semibold leading-tight">
            <span className="text-sm font-light text-gray-500">Welcome,</span>
            <br />
            {user.business?.name || user.name}
          </p>
        </div>
        {/* Profile Completion Bar */}
        {user.user_state?.name === REGISTRATION_PENDING_STATUS && (
          <>
            <div className="mx-2 my-4">
              <div className="flex justify-between mb-1 text-lg font-semibold">
                Profile Completion: {userSummary.profile_completion_percentage}%
                <TextLink className="self-end text-sm">
                  <Link href="/register">Complete Now</Link>
                </TextLink>
              </div>
              <div className="w-full bg-gray-50 rounded-full h-3.5 shadow-md">
                <div
                  className="bg-gradient-to-b from-dark-100 to-dark-400 h-3.5 rounded-full"
                  style={{
                    width: `${userSummary?.profile_completion_percentage}%`,
                  }}
                />
              </div>
            </div>
            <Link href="/register">
              <Button>Complete your profile</Button>
            </Link>
          </>
        )}
      </Card>
      <div className="flex justify-around mb-3 font-medium text-center text-gray-300 border-b-2">
        {SECTIONS.map((section) => (
          <span
            key={section}
            onClick={() => setSelectedSection(section)}
            className={
              section === selectedSection
                ? "w-full text-[#f27a21] border-b-2 border-[#f27a21] -mb-0.5"
                : "w-full cursor-pointer"
            }
          >
            {section}
          </span>
        ))}
      </div>
      <div className="flex flex-col justify-between px-5 content-container">
        <div>
          {selectedSection === SECTIONS[0] ? (
            <>
              <p className="mt-3 text-sm text-[#bdbdbd]">Business Type</p>
              <h3 className="font-semibold">{user.business?.business_type}</h3>
              <p className="mt-3 text-sm text-[#bdbdbd]">Contact No</p>
              <h3 className="font-semibold">{`+91 ${user.phone}`}</h3>
              <p className="mt-3 text-sm text-[#bdbdbd]">E-mail</p>
              <h3 className="font-semibold">{user.email}</h3>
              {!isUndefined(billingAddress) && (
                <>
                  <p className="mt-3 text-sm text-[#bdbdbd]">Billing Address</p>
                  <h3 className="font-semibold">{billingAddress.alias}</h3>
                  <h3 className="font-semibold">{billingAddress.address1}</h3>
                  <h3 className="font-semibold">{billingAddress.address2}</h3>
                  <h3 className="font-semibold">{billingAddress.area}</h3>
                  <h3 className="font-semibold">
                    {billingAddress.city}, {billingAddress.state}
                  </h3>
                  <h3 className="font-semibold">{billingAddress.pin_code}</h3>
                </>
              )}
              {shippingAddresses.length > 0 && (
                <>
                  <p className="mt-3 text-sm text-[#bdbdbd]">
                    Shipping Address
                  </p>
                  {shippingAddresses.map((address) => (
                    <div key={address.id} className="mb-2">
                      <h3 className="font-semibold">{address.alias}</h3>
                      <h3 className="font-semibold">{address.address1}</h3>
                      <h3 className="font-semibold">{address.address2}</h3>
                      <h3 className="font-semibold">{address.area}</h3>
                      <h3 className="font-semibold">
                        {address.city}, {address.state}
                      </h3>
                      <h3 className="font-semibold">{address.pin_code}</h3>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              <p className="mt-3 text-sm text-[#bdbdbd]">GSTIN Number</p>
              <h3 className="font-semibold">
                {user.business?.gst_num || "N/A"}
              </h3>
              <p className="mt-3 text-sm text-[#bdbdbd]">Aadhar Number</p>
              <h3 className="font-semibold">
                {user.business?.aadhaar_num || "N/A"}
              </h3>
              <p className="mt-3 text-sm text-[#bdbdbd]">PAN Number</p>
              <h3 className="font-semibold">
                {user.business?.pan_num || "N/A"}
              </h3>
            </>
          )}
        </div>
        {selectedSection === SECTIONS[1] && (
          <p className="text-center text-[#bdbdbd]">
            To make any changes to your business details, kindly{" "}
            <TextLink>
              <a href="tel:+919311962746">contact support</a>
            </TextLink>
          </p>
        )}
      </div>
    </div>
  );
};

export const getServerSideProps = async ({ req, res,query }) => {
  res.setHeader(
    "Cache-Control",
    "no-cache, no-store, max-age=0, must-revalidate"
  );
  const { cookies } = req;
  const userId = query?.userId || cookies.userId;
  const userToken = query?.userToken || cookies.userToken;
  let user;
  const config = {
    headers: {
      Authorization: "Token " + userToken,
    },
  };
  if (userId && userToken) {
    user = await getUser({ userId: userId }, config);
  } else {
    return {
      redirect: {
        destination: "/login",
        permanent: false,
      },
    };
  }

  const userSummary = await getUserSummary({ userId: userId }, config);
  if (
    userSummary.user_state?.name === REGISTRATION_PENDING_STATUS &&
    userSummary.profile_completion_percentage === 100
  ) {
    user.user_state.name = VERIFICATION_PENDING_STATUS;
    user.user_state.user_display_str = VERIFICATION_PENDING_STATUS_DISPLAY;
  }
  const userAddresses = await getUserAddresses(
    { userId: userId },
    config
  );

  return {
    props: {
      userSummary,
      userAddresses: userAddresses,
      user,
    },
  };
};

export default Profile;
